import { createContext } from 'react';
import { models } from 'data-plumber';

// Time and Space Actions Context

type TimeAndSpaceActions = {
  openTodoSideSheet: (_todo: models.ITodo) => void;
  openWagonModal: (_wagon: models.ITaktWagon) => void;
  taktSectionClicked: (
    _space: models.ISpace,
    _takt: models.ITakt,
    _callback: () => unknown
  ) => void;
  onDeleteTrain: (_train: models.ITaktTrain) => void;
  createStandaloneTodo: (space: models.ISpace, startDate: Date, endDate: Date) => void;
  setDetailLevel: (detail: 1|2|3|undefined) => void;
};

const defaultActions: TimeAndSpaceActions = {
  openTodoSideSheet: () => null,
  openWagonModal: () => null,
  taktSectionClicked: () => null,
  onDeleteTrain: () => null,
  createStandaloneTodo: () => null,
  setDetailLevel: () => null,
};

export const TimeAndSpaceActionsContext = createContext(defaultActions);

// Schedule Dimensions Context

type todoBeingCreated = {
  space: models.ISpace;
  startDate: Date;
  endDate: Date;
};

type ScheduleDimensions = {
  secondWidth: number;
  rowBaseHeight: number;
  topHeaderHeight: number;
  leftHeaderWidth: number;
  timeOrigin: Date;
  timeEnd: Date;
  isPanning: boolean;
  isDragging: boolean;
  trainCreationMode: boolean;
  standaloneTodoBeingCreated: todoBeingCreated | null;
  selectedTool: 'delete' | 'move' | 'createStandaloneTodo' | null;
  detailLevel: 1 | 2 | 3; 
};

const defaultScheduleDimensions: ScheduleDimensions = {
  secondWidth: 1 / 60 / 60 / 5,
  rowBaseHeight: 100,
  topHeaderHeight: 75,
  leftHeaderWidth: 100,
  timeOrigin: new Date(),
  timeEnd: new Date(),
  isPanning: false,
  isDragging: false,
  trainCreationMode: false,
  selectedTool: null,
  standaloneTodoBeingCreated: null,
  detailLevel: 1
};

export const ScheduleDimensionsContext = createContext(defaultScheduleDimensions);

// Takt Schedule Context

type TaktScheduleContextType = {
  taktsByIndex: models.ITakt[] | undefined;
  selectedTaktTrainTemplate: models.ITaktTrainTemplate | undefined;
};

const defaultTaktSchedule: TaktScheduleContextType = {
  taktsByIndex: [],
  selectedTaktTrainTemplate: undefined,
};

export const TaktScheduleContext = createContext(defaultTaktSchedule);

//  Schedule Provider

type ScheduleProviderProps = {
  selectedProject: models.IProject | null;
  selectedTaktTrainTemplate: models.ITaktTrainTemplate | null;
  actions: TimeAndSpaceActions;
  scheduleDimensions: Partial<ScheduleDimensions>;
  children: React.ReactNode;
};

export const ScheduleProvider: React.FC<ScheduleProviderProps> = ({
  selectedProject,
  selectedTaktTrainTemplate,
  actions,
  scheduleDimensions = {},
  children,
}) => {
  return (
    <TaktScheduleContext.Provider
      value={{
        taktsByIndex: selectedProject?.taktsByIndex,
        selectedTaktTrainTemplate: selectedTaktTrainTemplate || undefined,
      }}
    >
      <TimeAndSpaceActionsContext.Provider value={actions}>
        {selectedProject && (
          <ScheduleDimensionsContext.Provider
            value={{
              ...defaultScheduleDimensions,
              ...scheduleDimensions,
            }}
          >
            {children}
          </ScheduleDimensionsContext.Provider>
        )}
      </TimeAndSpaceActionsContext.Provider>
    </TaktScheduleContext.Provider>
  );
};
