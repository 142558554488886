import React, { useId } from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';

import { cn } from '@/utils';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    label?: string | React.ReactNode;
  }
>(({ className, label, ...props }, ref) => {
  // Generate a unique id for the checkbox
  const checkboxId = useId();

  return (
    <div className='flex flex-row'>
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
          'peer h-6 w-6 shrink-0 rounded-sm border-2 border-neutral shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-teal-700 data-[state=checked]:text-secondary transition-all',
          className
        )}
        id={checkboxId}
        {...props}
      >
        <CheckboxPrimitive.Indicator
          className={cn('flex items-center justify-center text-current')}
        >
          <Check className='h-5 w-5' />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      {label && (
        <label
          htmlFor={checkboxId}
          className='ml-4 text-base cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
        >
          {label}
        </label>
      )}
    </div>
  );
});

export { Checkbox };
