import {
  __export
} from "./chunk-QXAXOUZS.mjs";

// src/data/models/index.ts
var models_exports = {};
__export(models_exports, {
  AuthenticatedUserModel: () => AuthenticatedUserModel,
  ChecklistItemModel: () => ChecklistItemModel,
  CommentModel: () => CommentModel,
  CompanyMembershipModel: () => CompanyMembershipModel,
  CompanyModel: () => CompanyModel,
  InterruptionModel: () => InterruptionModel,
  InterruptionReasons: () => InterruptionReasons,
  OwnMembershipInviteModel: () => OwnMembershipInviteModel,
  ProjectAnalyticsModel: () => ProjectAnalyticsModel,
  ProjectMembershipInviteModel: () => ProjectMembershipInviteModel,
  ProjectMembershipModel: () => ProjectMembershipModel,
  ProjectMembershipRequestModel: () => ProjectMembershipRequestModel,
  ProjectModel: () => ProjectModel,
  SpaceModel: () => SpaceModel,
  TaktModel: () => TaktModel,
  TaktTrainModel: () => TaktTrainModel,
  TaktTrainTemplateModel: () => TaktTrainTemplateModel,
  TaktWagonModel: () => TaktWagonModel,
  TaktWagonTemplateModel: () => TaktWagonTemplateModel,
  TaskTemplateModel: () => TaskTemplateModel,
  TeamMembershipModel: () => TeamMembershipModel,
  TeamModel: () => TeamModel,
  TodoEventModel: () => TodoEventModel,
  TodoModel: () => TodoModel,
  UserModel: () => UserModel
});

// src/data/models/user/User.ts
import { types as types7, flow as flow4, applySnapshot as applySnapshot5, toGenerator as toGenerator4, resolveIdentifier as resolveIdentifier2 } from "mobx-state-tree";

// src/data/models/project/team/TeamMembership.ts
import { types as types2 } from "mobx-state-tree";

// src/data/utils/withRestActions.ts
import { applySnapshot as applySnapshot2, flow, toGenerator } from "mobx-state-tree";

// src/data/utils/applyPartialSnapshot.ts
import { applySnapshot, getSnapshot } from "mobx-state-tree";
var resolveDeepSnapshotMerge = (object, newSnapshot) => {
  const currentSnapshot = getSnapshot(object);
  const mergedSnapshot = { ...currentSnapshot, ...newSnapshot };
  return mergedSnapshot;
};
var applyPartialSnapshot = (object, snapshot) => {
  const newSnapshot = resolveDeepSnapshotMerge(object, snapshot);
  applySnapshot(object, newSnapshot);
};

// src/data/utils/withRestActions.ts
var withMestamasterRestActions = (getRestModule) => {
  return (self) => ({
    partialUpdate: flow(function* (data) {
      const response = yield* toGenerator(
        getRestModule(self).partialUpdate(self.id, data)
      );
      if (response.kind === "ok" && response.data) {
        applyPartialSnapshot(self, response.data);
      } else {
        console.error(`Error fetching project membership requests: ${JSON.stringify(response)}`, []);
      }
      return response;
    }),
    refresh: flow(function* () {
      const response = yield* toGenerator(
        getRestModule(self).get(self.id)
      );
      if (response.kind === "ok" && response.data) {
        applyPartialSnapshot(self, response.data);
      } else {
        console.error(`Error fetching project membership requests: ${JSON.stringify(response)}`, []);
      }
      return response;
    }),
    update: flow(function* (data) {
      const response = yield* toGenerator(
        getRestModule(self).update(self.id, data)
      );
      if (response.kind === "ok" && response.data) {
        applySnapshot2(self, response.data);
      } else {
        console.error(`Error fetching project membership requests: ${JSON.stringify(response)}`, []);
      }
      return response;
    }),
    delete: flow(function* () {
      const response = yield* toGenerator(
        getRestModule(self).delete(self.id)
      );
      if (response.kind === "ok") {
        self.selfDestroy();
      } else {
        console.error(`Error fetching project membership requests: ${JSON.stringify(response)}`, []);
      }
      return response;
    })
  });
};

// src/data/models/BaseRestModel.ts
import { destroy as destroy2, getParent, types } from "mobx-state-tree";

// src/data/utils/withApi.ts
import { getEnv } from "mobx-state-tree";
var withApi = (self) => ({
  views: {
    get mestamasterApi() {
      return getEnv(self).mestamasterApi;
    },
    get cableConnection() {
      return getEnv(self).cableConnection;
    }
  }
});

// src/data/utils/withRoot.ts
import { getRoot } from "mobx-state-tree";
var withRoot = (self) => ({
  views: {
    get root() {
      return getRoot(self);
    }
  }
});

// src/data/models/BaseRestModel.ts
var BaseModel = types.model("BaseModel").props({}).extend(withRoot).actions((self) => ({
  removeChild(child) {
    destroy2(child);
  }
}));
var BaseRestModel = BaseModel.named("BaseRestModel").props({
  id: types.identifierNumber
}).extend(withApi).extend(withRoot).actions((self) => ({
  selfDestroy() {
    getParent(self, 2).removeChild(self);
  }
}));

// src/data/models/project/team/TeamMembership.ts
var TeamMembershipModel = BaseRestModel.named("TeamMembership").props({
  id: types2.identifierNumber,
  teamId: types2.number,
  userId: types2.number
}).actions(withMestamasterRestActions((self) => self.mestamasterApi.teamMemberships)).views((self) => ({})).actions((self) => ({}));

// src/data/models/project/project-memberships/ProjectMembershipInvite.ts
import { applySnapshot as applySnapshot3, flow as flow2, toGenerator as toGenerator2, types as types4 } from "mobx-state-tree";

// src/data/fields/IsoDate.ts
import { types as types3 } from "mobx-state-tree";
function validateDate(str) {
  const date = Date.parse(str);
  if (isNaN(date))
    throw new Error("Invalid date");
  return new Date(date);
}
var IsoDate = types3.custom({
  name: "IsoDate",
  fromSnapshot(value) {
    return validateDate(value);
  },
  toSnapshot(value) {
    return value.toISOString();
  },
  isTargetType(maybeDate) {
    return maybeDate instanceof Date;
  },
  getValidationMessage(snapshot) {
    try {
      validateDate(snapshot);
      return "";
    } catch (error) {
      return error.message;
    }
  }
});

// src/data/models/project/project-memberships/ProjectMembershipInvite.ts
var InviteCompany = types4.model("InviteCompany").props({
  id: types4.number,
  name: types4.string
});
var Inviter = types4.model("Inviter").props({
  id: types4.number,
  firstName: types4.string,
  lastName: types4.string
});
var InviteProject = types4.model("InviteProject").props({
  id: types4.number,
  name: types4.string,
  company: InviteCompany,
  address: types4.string
});
var ProjectMembershipInviteModel = types4.model("ProjectMembershipInvite").props({
  id: types4.identifierNumber,
  projectId: types4.number,
  inviterId: types4.number,
  state: types4.enumeration("State", ["pending", "accepted", "rejected"]),
  role: types4.enumeration("Role", ["member", "manager", "guest"]),
  createdAt: IsoDate,
  project: InviteProject,
  inviter: Inviter,
  phoneNumber: types4.maybeNull(types4.string),
  email: types4.maybeNull(types4.string),
  teamIds: types4.optional(types4.array(types4.number), [])
}).extend(withApi).extend(withRoot).views((self) => ({})).actions((self) => ({
  resend: flow2(function* () {
    const response = yield* toGenerator2(self.mestamasterApi.projectMemberships.resendProjectMembershipInvite(self.id));
    return response;
  }),
  accept: flow2(
    function* () {
      const response = yield* toGenerator2(self.mestamasterApi.projectMemberships.acceptProjectMembershipInvite(self.id));
      if (response.kind === "ok") {
        applySnapshot3(self, response.data);
        self.root.projectsStore.fetchProjects();
      }
      return response;
    }
  ),
  reject: flow2(
    function* () {
      const response = yield* toGenerator2(self.mestamasterApi.projectMemberships.rejectProjectMembershipInvite(self.id));
      if (response.kind === "ok") {
        applySnapshot3(self, response.data);
      }
      return response;
    }
  ),
  partialUpdate: flow2(
    function* (data) {
      const response = yield* toGenerator2(self.mestamasterApi.projectMemberships.updateProjectMembershipInvite(self.id, data));
      if (response.kind === "ok") {
        applySnapshot3(self, response.data);
      }
      return response;
    }
  )
}));
var OwnMembershipInviteModel = ProjectMembershipInviteModel.named("OwnMembershipInvite");

// src/data/utils/withCollectionTools.ts
import { getType, applySnapshot as applySnapshot4, getSnapshot as getSnapshot2, detach as detach2, destroy as destroy3 } from "mobx-state-tree";
var applyMapPartialSnapshot = (node, snapshot) => {
  const currentSnapshot = getSnapshot2(node);
  const newSnapshot = { ...currentSnapshot, ...snapshot };
  return applySnapshot4(node, newSnapshot);
};
var withCollectionTools = (mstInstance) => ({
  addOrUpdateEntityToMap(node, element) {
    const mapType = getType(node);
    const idAttribute = mapType == null ? void 0 : mapType.mapIdentifierAttribute;
    if (!idAttribute) {
      throw new Error("Map type must have an identifier attribute");
    }
    const nodeId = (element == null ? void 0 : element.identifier) || element[idAttribute];
    if (node.has(nodeId)) {
      applyPartialSnapshot(getSnapshot2(node.get(nodeId)), element);
    } else {
      node.put(element);
    }
  },
  addOrUpdateMultipleEntitiesToMap(node, elements, shallowMerge) {
    const mapType = getType(node);
    const idAttribute = mapType == null ? void 0 : mapType.mapIdentifierAttribute;
    if (!elements) {
      return;
    }
    if (!idAttribute) {
      throw new Error("Map type must have an identifier attribute");
    }
    const mapSnapshot = elements.reduce((acc, element) => {
      const nodeId = (element == null ? void 0 : element.identifier) || element[idAttribute];
      if (!shallowMerge && node.has(nodeId)) {
        acc[nodeId] = resolveDeepSnapshotMerge(node.get(nodeId), element);
        return acc;
      }
      acc[nodeId] = element;
      return acc;
    }, {});
    applyMapPartialSnapshot(node, mapSnapshot);
  },
  cleanupMap(node, expectedIds) {
    const mapType = getType(node);
    const idAttribute = mapType == null ? void 0 : mapType.mapIdentifierAttribute;
    node.forEach((element, key) => {
      const nodeId = (element == null ? void 0 : element.identifier) || element[idAttribute];
      if (!expectedIds.includes(nodeId)) {
        detach2(node.get(key));
        node.delete(key);
      }
    });
  },
  addToArray(node, element) {
    node.push(element);
  },
  cleanupArray(node, expectedIds, idExtractor) {
    node.forEach((element) => {
      const nodeId = idExtractor(element);
      if (!expectedIds.includes(nodeId.toString())) {
        detach2(element);
        destroy3(element);
      }
    });
  },
  applyArraySnapshotWithCleanup(node, snapshot, idExtractor) {
    applySnapshot4(node, snapshot);
    const ids = snapshot.map(idExtractor);
    this.cleanupArray(node, ids, idExtractor);
  }
});

// src/data/models/project/team/Team.ts
import {
  flow as flow3,
  resolveIdentifier,
  toGenerator as toGenerator3,
  types as types5
} from "mobx-state-tree";
var TeamModel = BaseModel.named("Team").props({
  id: types5.identifierNumber,
  name: types5.string,
  color: types5.maybeNull(types5.string),
  order: types5.number,
  isManagerTeam: types5.optional(types5.boolean, false),
  teamMemberships: types5.optional(types5.array(TeamMembershipModel), []),
  analytics: types5.optional(
    types5.model({
      spaceCount: types5.number,
      taskCount: types5.number,
      totalHours: types5.number
    }),
    { spaceCount: 0, taskCount: 0, totalHours: 0 }
  )
}).extend(withRoot).extend(withApi).views((self) => ({
  get teamUsers() {
    return self.teamMemberships.map((membership) => {
      return resolveIdentifier(UserModel, self.root, membership.userId);
    }).filter((user) => user !== void 0);
  }
})).actions((self) => ({
  addTeamMembership: flow3(function* (userId) {
    const response = yield* toGenerator3(
      self.mestamasterApi.teamMemberships.create({ teamId: self.id, userId })
    );
    if (response.kind === "ok") {
      self.teamMemberships.push(response.data);
    } else {
      console.error(`Error adding user to team: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  removeTeamMembership: flow3(function* (userId) {
    const teamMembership = self.teamMemberships.find(
      (membership) => membership.userId === userId
    );
    if (teamMembership) {
      yield teamMembership.delete();
    }
  })
}));

// src/data/models/user/UserProjectPulses.ts
import { types as types6 } from "mobx-state-tree";
var UserProjectPulse = types6.model("UserProjectPulse").props({
  id: types6.identifierNumber,
  userId: types6.number,
  createdAt: IsoDate,
  value: types6.maybeNull(types6.number),
  projectId: types6.number,
  skipped: types6.optional(types6.boolean, false)
}).views((self) => ({})).actions((self) => ({}));

// src/data/models/user/User.ts
var UserModel = BaseModel.named("User").props({
  id: types7.identifierNumber,
  firstName: types7.string,
  lastName: types7.string,
  email: types7.maybeNull(types7.string),
  lang: types7.maybeNull(types7.string),
  phoneNumber: types7.maybeNull(types7.string),
  teamMemberships: types7.optional(types7.array(TeamMembershipModel), [])
}).extend(withApi).extend(withRoot).actions(withCollectionTools).views((self) => ({
  get fullName() {
    return `${self.firstName} ${self.lastName}`;
  },
  get abbreviatedName() {
    return `${self.firstName}${self.lastName ? " " + self.lastName[0] + "." : ""}`;
  },
  get teamMembershipsTeamIdArray() {
    return self.teamMemberships.map((teamMembership) => teamMembership.teamId);
  },
  get teams() {
    return self.teamMemberships.map((teamMembership) => resolveIdentifier2(self.root, TeamModel, teamMembership.teamId));
  }
})).actions((self) => ({
  async setTeamMemberships(teamMemberships) {
    applySnapshot5(self.teamMemberships, teamMemberships);
  }
})).actions((self) => ({
  loadTeamMemberships: flow4(function* () {
    const response = yield* toGenerator4(self.mestamasterApi.fetchUserTeamMemberships(self.id));
    self.setTeamMemberships(response.data);
  }),
  fetchDetails: flow4(function* () {
    const response = yield* toGenerator4(self.mestamasterApi.users.getUser(self.id));
    if (response.kind === "ok") {
      applySnapshot5(self, response.data);
      return response;
    } else {
      console.error(`Error fetching User Details: ${JSON.stringify(response)}`, []);
      return response;
    }
  }),
  addToTeam: flow4(function* (teamId) {
    const response = yield* toGenerator4(self.mestamasterApi.teamMemberships.create({ teamId, userId: self.id }));
    if (response.kind === "ok") {
      self.teamMemberships.push(response.data);
    } else {
      console.error(`Error adding user to team: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  removeFromTeam: flow4(function* (teamId) {
    const teamMembership = self.teamMemberships.find((membership) => membership.teamId === teamId);
    if (!teamMembership) {
      return;
    }
    yield teamMembership.delete();
  })
}));
var AuthenticatedUserModel = UserModel.named("AuthenticatedUser").props({
  firstName: types7.maybeNull(types7.string),
  lastName: types7.maybeNull(types7.string),
  gdprConsent: types7.maybe(types7.boolean),
  betaTester: types7.maybe(types7.boolean),
  onboarded: types7.maybe(types7.boolean),
  emailVerified: types7.maybe(types7.boolean),
  phoneNumberVerified: types7.maybe(types7.boolean),
  projectMembershipInvites: types7.optional(types7.array(OwnMembershipInviteModel), []),
  projectPulses: types7.optional(types7.maybeNull(types7.array(UserProjectPulse)), null),
  lastSkipedrojectPulse: types7.optional(types7.maybeNull(IsoDate), null)
}).views((self) => ({
  get hasNotifications() {
    return self.projectMembershipInvites.length > 0;
  },
  get openProjectMembershipInvites() {
    return self.projectMembershipInvites.filter((invite) => invite.state === "pending");
  },
  openProjectMembershipInvitesByCompaniesMap(forceIncludedProjectIds) {
    const map = /* @__PURE__ */ new Map();
    Array.from(self.projectMembershipInvites.values()).filter((invite) => invite.state === "pending" || forceIncludedProjectIds.includes(invite.id)).forEach((invite) => {
      var _a;
      const companyId = invite.project.company.id;
      const companyName = invite.project.company.name;
      if (!map.has(companyId)) {
        map.set(companyId, { companyId, companyName, invites: [] });
      }
      (_a = map.get(companyId)) == null ? void 0 : _a.invites.push(invite);
    });
    return map;
  },
  latestPulseDateForProject(projectId) {
    if (self.projectPulses === null || self.projectPulses === void 0) {
      return void 0;
    }
    const forProject = self.projectPulses.filter((pulse) => pulse.projectId === projectId);
    return forProject.reduce((latest, pulse) => {
      return pulse.createdAt > latest ? pulse.createdAt : latest;
    }, new Date(0));
  },
  pendingProjectMembershipInvitesByCompanyId(companyId) {
    return self.projectMembershipInvites.filter((invite) => invite.project.company.id === companyId && invite.state === "pending");
  }
})).actions((self) => ({
  fetchSelfDetails: flow4(function* () {
    const response = yield* toGenerator4(self.mestamasterApi.users.fetchCurrentUser());
    if (response.kind === "ok") {
      applySnapshot5(self, response.data);
      return response;
    } else {
      console.error(`Error fetching Current User: ${JSON.stringify(response)}`, []);
      return response;
    }
  }),
  updateSelf: flow4(function* (user) {
    const response = yield* toGenerator4(self.mestamasterApi.users.updateCurrentUser(user));
    if (response.kind === "ok") {
      applyPartialSnapshot(self, response.data);
    } else {
      console.error(`Error updating current user: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  fetchProjectMembershipInvites: flow4(function* () {
    const response = yield* toGenerator4(self.mestamasterApi.projectMemberships.fetchCurrentUserProjectMembershipInvites());
    if (response.kind === "ok") {
      self.applyArraySnapshotWithCleanup(self.projectMembershipInvites, response.data, (el) => el.id.toString());
    } else {
      console.error(`Error fetching Project Membership Invites: ${JSON.stringify(response)}`, []);
    }
  }),
  requestEmailVerification: flow4(function* () {
    const response = yield* toGenerator4(self.mestamasterApi.users.requestEmailVerification());
    if (response.kind === "ok") {
      return response;
    }
    return response;
  }),
  requestPhoneVerification: flow4(function* () {
    const response = yield* toGenerator4(self.mestamasterApi.users.requestPhoneVerification());
    if (response.kind === "ok") {
      return response;
    }
    return response;
  }),
  verifyEmail: flow4(function* (token) {
    const response = yield* toGenerator4(self.mestamasterApi.users.verifyEmail(token));
    if (response.kind === "ok") {
      self.emailVerified = response.data.emailVerified;
      return response;
    }
    return response;
  }),
  verifyPhone: flow4(function* (token) {
    const response = yield* toGenerator4(self.mestamasterApi.users.verifyPhone(token));
    if (response.kind === "ok") {
      self.phoneNumberVerified = response.data.phoneNumberVerified;
      return response;
    }
    return response;
  }),
  sendProjectPulse: flow4(function* (projectId, value, skipped) {
    const response = yield* toGenerator4(self.mestamasterApi.projects.sendProjectPulse(projectId, value, skipped));
    if (response.kind === "ok") {
      self.addToArray(self.projectPulses, response.data);
    } else {
      console.error(`Error sending project pulse: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  loadProjectPulses: flow4(function* () {
    const response = yield* toGenerator4(self.mestamasterApi.users.fetchCurrentUserProjectPulses());
    if (response.kind === "ok") {
      if (!self.projectPulses) {
        self.projectPulses = response.data;
      } else {
        applySnapshot5(self.projectPulses, response.data);
      }
    } else {
      console.error(`Error fetching Project Pulses: ${JSON.stringify(response)}`, []);
    }
  })
})).actions((self) => ({
  refresh: flow4(function* () {
    yield self.fetchSelfDetails();
    yield self.loadTeamMemberships();
    yield self.fetchProjectMembershipInvites();
    yield self.loadProjectPulses();
  })
})).actions((self) => ({
  subscribeToChannel({
    onNewProjectMembershipInvite,
    onAcceptedProjectMembershipInvite
  } = {}) {
    var _a;
    (_a = self.cableConnection) == null ? void 0 : _a.subscribe(
      "V3UserMembershipEventsChannel",
      { user_id: self.id.toString() },
      (data) => {
        const eventType = data.event;
        if (eventType === "new:ProjectMembershipInvite" /* NEW_PROJECT_MEMBERSHIP_INVITE */) {
          self.addToArray(self.projectMembershipInvites, data.data);
          if (onNewProjectMembershipInvite) {
            onNewProjectMembershipInvite(
              self.projectMembershipInvites[self.projectMembershipInvites.length - 1]
            );
          }
        } else if (eventType === "accepted:ProjectMembershipInvite" /* ACCEPT_PROJECT_MEMBERSHIP_INVITE */) {
          const invite = data.data;
          const existingInvite = resolveIdentifier2(ProjectMembershipInviteModel, self.root, invite.id);
          console.log("existingInvite", existingInvite);
          if (existingInvite && onAcceptedProjectMembershipInvite) {
            applyPartialSnapshot(existingInvite, invite);
            onAcceptedProjectMembershipInvite(existingInvite);
          }
        }
      }
    );
  }
}));

// src/data/models/company/Company.ts
import {
  applySnapshot as applySnapshot7,
  flow as flow6,
  resolveIdentifier as resolveIdentifier4,
  toGenerator as toGenerator6,
  types as types9
} from "mobx-state-tree";

// src/data/models/company/CompanyMembership.ts
import { resolveIdentifier as resolveIdentifier3, types as types8 } from "mobx-state-tree";
var CompanyMembershipModel = BaseRestModel.named("CompanyMembership").props({
  id: types8.identifierNumber,
  userId: types8.number,
  role: types8.enumeration("Role", ["member", "manager", "guest", "administrator"]),
  createdAt: IsoDate
}).actions(withMestamasterRestActions((self) => self.mestamasterApi.companyMemberships)).views((self) => ({
  get user() {
    return resolveIdentifier3(UserModel, self.root, self.userId);
  }
}));

// src/data/models/company/Company.ts
var CompanyModel = types9.model("Company").props({
  id: types9.identifierNumber,
  name: types9.string,
  companyMemberships: types9.late(() => types9.optional(types9.array(CompanyMembershipModel), []))
}).extend(withApi).extend(withRoot).views((self) => ({
  getCompanyMembershipForAddToTeam(teamId) {
    if (!teamId)
      return [];
    const team = resolveIdentifier4(TeamModel, self.root, teamId);
    if (team) {
      const teamMemberships = team.teamMemberships;
      return self.companyMemberships.filter(
        (companyMembership) => !teamMemberships.some(
          (teamMembership) => teamMembership.userId === companyMembership.userId
        )
      );
    }
    return [];
  }
})).actions((self) => ({
  fetchCompanyMemberships: flow6(function* () {
    const response = yield* toGenerator6(
      self.mestamasterApi.companies.getCompanyMemberships(self.id)
    );
    if (response.kind === "ok") {
      const users = response.data.map((companyMembership) => companyMembership.user);
      self.root.usersStore.addUsers(users);
      applySnapshot7(self.companyMemberships, response.data);
    }
    return response;
  })
}));

// src/data/models/project/Project.ts
import {
  applySnapshot as applySnapshot14,
  getSnapshot as getSnapshot3,
  flow as flow14,
  toGenerator as toGenerator14,
  types as types27,
  destroy as destroy7,
  detach as detach4,
  resolveIdentifier as resolveIdentifier15
} from "mobx-state-tree";

// src/data/utils/withSetPropAction.ts
var withSetPropAction = (mstInstance) => ({
  setProp(field, newValue) {
    mstInstance[field] = newValue;
  }
});

// src/data/models/project/todo/Todo.ts
import { resolveIdentifier as resolveIdentifier9, types as types15 } from "mobx-state-tree";

// src/data/models/project/todo/comment/Comment.ts
import { resolveIdentifier as resolveIdentifier5, types as types10 } from "mobx-state-tree";
var AttachmentModel = types10.model("Attachment").props({
  url: types10.string
});
var CommentModel = types10.model("Comment").props({
  id: types10.identifierNumber,
  content: types10.maybeNull(types10.string),
  createdAt: IsoDate,
  updatedAt: IsoDate,
  authorId: types10.number,
  attachments: types10.optional(types10.array(AttachmentModel), [])
}).extend(withRoot).views((self) => ({
  get author() {
    return resolveIdentifier5(UserModel, self.root, self.authorId);
  }
})).actions((self) => ({}));

// src/data/models/project/todo/interruption/Interruption.ts
import { resolveIdentifier as resolveIdentifier6, types as types11 } from "mobx-state-tree";
var InterruptionReasons = {
  materials: "materials",
  necessities: "necessities",
  plans: "plans",
  equipments: "equipments",
  prevTodo: "prev_todo",
  spaceNotAvailable: "space_not_available",
  spaceNotClean: "space_not_clean",
  other: "other",
  notDefined: "not_defined"
};
var InterruptionModel = types11.model("Interruption").props({
  id: types11.identifierNumber,
  state: types11.enumeration(["unresolved", "resolved"]),
  reason: types11.enumeration(Object.values(InterruptionReasons)),
  comment: types11.maybeNull(types11.string),
  createdAt: IsoDate,
  authorId: types11.number
}).extend(withRoot).views((self) => ({
  get author() {
    return resolveIdentifier6(UserModel, self.root.usersStore, self.authorId);
  }
})).actions((self) => ({}));

// src/data/models/project/space/Space.ts
import { types as types12 } from "mobx-state-tree";
var SpaceModel = types12.model("Space").props({
  id: types12.identifierNumber,
  name: types12.string,
  address: types12.string,
  description: types12.maybeNull(types12.string),
  order: types12.number,
  startsOn: types12.number,
  parentId: types12.maybeNull(types12.number)
}).views((self) => ({})).actions((self) => ({}));

// src/data/models/project/todo/todoEvent/TodoEvent.ts
import { resolveIdentifier as resolveIdentifier7, types as types13 } from "mobx-state-tree";
var TodoEventModel = types13.model("TodoEvent").props({
  id: types13.identifierNumber,
  event: types13.enumeration([
    "started",
    "cancelled",
    "completed",
    "continued",
    "skipped",
    "unskipped",
    "interrupted",
    "resolved",
    "deleted"
  ]),
  createdAt: IsoDate,
  updatedAt: IsoDate,
  todoId: types13.number,
  userId: types13.maybe(types13.number)
}).extend(withRoot).views((self) => ({
  get author() {
    if (!self.userId)
      return null;
    return resolveIdentifier7(UserModel, self.root, self.userId);
  },
  get todo() {
    return resolveIdentifier7(TodoModel, self.root, self.todoId);
  }
})).actions((self) => ({}));

// src/data/models/project/todo/checklistItem/ChecklistItem.ts
import { applySnapshot as applySnapshot8, flow as flow7, resolveIdentifier as resolveIdentifier8, toGenerator as toGenerator7, types as types14 } from "mobx-state-tree";
var ChecklistItemModel = types14.model("ChecklistItem").props({
  id: types14.identifierNumber,
  completedAt: types14.maybeNull(IsoDate),
  userId: types14.maybeNull(types14.number),
  title: types14.string,
  createdAt: IsoDate,
  order: types14.optional(types14.number, 0)
}).extend(withRoot).extend(withApi).views((self) => ({
  get author() {
    if (!self.userId) {
      return null;
    }
    return resolveIdentifier8(UserModel, self.root, self.userId);
  }
})).actions((self) => ({
  toggle: flow7(function* () {
    if (self.completedAt) {
      const response = yield* toGenerator7(self.mestamasterApi.undoChecklistItem(self.id));
      applySnapshot8(self, response.data);
    } else {
      const response = yield* toGenerator7(self.mestamasterApi.completeChecklistItem(self.id));
      applySnapshot8(self, response.data);
    }
  }),
  updateSelf: (data) => {
    applySnapshot8(self, data);
  }
}));

// src/data/models/project/todo/Todo.ts
import { now } from "mobx-utils";
import { isAfter, formatDistanceToNow, isTomorrow, isToday, format, isYesterday } from "date-fns";
var TodoModel = types15.model("Todo").props({
  id: types15.identifierNumber,
  projectId: types15.number,
  taskName: types15.string,
  estimatedDuration: types15.number,
  waitingDuration: types15.number,
  state: types15.string,
  takt: types15.maybeNull(types15.number),
  taktWagonId: types15.maybeNull(types15.number),
  startsAt: IsoDate,
  dueAt: types15.maybeNull(IsoDate),
  completedAt: types15.maybeNull(IsoDate),
  spaceId: types15.number,
  teamId: types15.maybeNull(types15.number),
  taskDescription: types15.maybe(types15.string),
  note: types15.maybeNull(types15.string),
  lastModifiedByUserId: types15.maybeNull(types15.number),
  hasCheckupItems: types15.optional(types15.boolean, false),
  comments: types15.optional(types15.map(CommentModel), {}),
  todoEvents: types15.optional(types15.map(types15.late(() => TodoEventModel)), {}),
  interruptions: types15.optional(types15.map(InterruptionModel), {}),
  updatedAt: IsoDate,
  checklistItems: types15.optional(types15.array(ChecklistItemModel), [])
}).extend(withRoot).extend(withApi).actions(withCollectionTools).views((self) => ({
  get checklistIsCompleted() {
    return self.checklistItems.every((item) => !!item.completedAt);
  },
  get estimatedHours() {
    return self.estimatedDuration / 60 * 10 / 10;
  },
  get todoEventArray() {
    return Array.from(self.todoEvents.values());
  },
  get feedItemsArray() {
    const items = [];
    items.push(...Array.from(self.interruptions.values()));
    self.todoEvents.forEach((todoEvent) => {
      if (todoEvent.event != "interrupted")
        items.push(todoEvent);
    });
    self.comments.forEach((comment) => items.push(comment));
    items.sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf());
    return items;
  },
  get orderedChecklistItems() {
    return self.checklistItems.slice().sort((a, b) => a.order - b.order);
  },
  get team() {
    if (!self.teamId)
      return void 0;
    return resolveIdentifier9(TeamModel, self.root, self.teamId);
  },
  get space() {
    return resolveIdentifier9(SpaceModel, self.root, self.spaceId);
  },
  get statusPaletteColor() {
    switch (self.state) {
      case "completed":
        return "success500";
      case "interrupted":
        return "angry500";
      case "started":
        return "primary200";
      default:
        return "floralWhite50";
    }
  },
  get interruptionArray() {
    return Array.from(self.interruptions.values());
  },
  get lastModifiedByUser() {
    if (!self.lastModifiedByUserId)
      return null;
    return resolveIdentifier9(UserModel, self.root, self.lastModifiedByUserId) || null;
  },
  humanDueAt(t) {
    let result = "";
    if (!self.dueAt)
      return result;
    const currentDate = now(6e3);
    const isDueAtMidnight = self.dueAt.getHours() === 23 && self.dueAt.getMinutes() === 59;
    const isDueToday = isToday(self.dueAt);
    const isDueTomorrow = isTomorrow(self.dueAt);
    const isDueYesterday = isYesterday(self.dueAt);
    const date = new Date(self.dueAt);
    if (isAfter(self.dueAt, currentDate)) {
      if (isDueToday) {
        result = t("today");
      } else if (isDueTomorrow) {
        result = t("tomorrow");
      } else {
        result = format(date, "dd/MM/yyyy");
      }
    } else {
      if (isDueYesterday) {
        result = t("yesterday");
      } else {
        result = formatDistanceToNow(date, { addSuffix: true });
        result = result + " (" + format(date, "dd/MM") + ")";
      }
    }
    if (!isDueAtMidnight) {
      result = `${result} ${t("at")} ${format(date, "HH:mm")}`;
    }
    return result;
  }
})).actions((self) => ({
  async loadDetails() {
    const response = await self.mestamasterApi.getTodoDetails(self.id);
    applyPartialSnapshot(self, response.data);
  },
  async loadComments() {
    const response = await self.mestamasterApi.getTodoComments(self.id);
    if (response.kind == "ok") {
      self.addOrUpdateMultipleEntitiesToMap(self.comments, response.data);
    }
  },
  async loadInterruptions() {
    const response = await self.mestamasterApi.getTodoInterruptions(self.id);
    if (response.kind == "ok") {
      self.addOrUpdateMultipleEntitiesToMap(self.interruptions, response.data);
    }
  },
  async loadTodoEvents() {
    const response = await self.mestamasterApi.getTodoEvents(self.id);
    if (response.kind == "ok") {
      self.addOrUpdateMultipleEntitiesToMap(self.todoEvents, response.data);
    }
  }
})).actions((self) => ({
  async start() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "start");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async createInterruption(comment, reason) {
    const response = await self.mestamasterApi.createTodoInterruption(self.id, comment, reason);
    self.addOrUpdateEntityToMap(self.interruptions, response.data);
    self.loadDetails();
    self.loadTodoEvents();
    return response;
  },
  async cancel() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "cancel");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async complete() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "complete");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async continue() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "continue");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async skip() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "skip");
    applyPartialSnapshot(self, response.data);
    return response;
  },
  async unskip() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "unskip");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async resolve() {
    const response = await self.mestamasterApi.emitTodoEvent(self.id, "resolve");
    applyPartialSnapshot(self, response.data);
    self.loadTodoEvents();
    return response;
  },
  async createComment(comment, files) {
    const response = await self.mestamasterApi.createTodoComment(self.id, comment, files);
    self.addOrUpdateEntityToMap(self.comments, response.data);
    return response;
  }
})).actions((self) => ({
  async loadAllEvents() {
    await self.loadComments();
    await self.loadInterruptions();
    await self.loadTodoEvents();
  }
})).actions((self) => ({
  async loadAll() {
    await self.loadDetails();
    await self.loadAllEvents();
  }
}));

// src/data/models/project/takt/Takt.ts
import { types as types16 } from "mobx-state-tree";
import { now as now2 } from "mobx-utils";
var UPDATE_VIEWS_MILLISECONDS = 6e4;
var TaktModel = types16.model("Takt").props({
  taktKey: types16.identifier,
  startsAt: IsoDate,
  endsAt: IsoDate,
  workdayEndsAt: IsoDate,
  taktIndex: types16.number,
  skipped: types16.optional(types16.boolean, false)
}).views((self) => ({
  get timeStatus() {
    const currentTime = now2(UPDATE_VIEWS_MILLISECONDS);
    if (self.endsAt.valueOf() < currentTime) {
      return "past";
    } else if (self.startsAt.valueOf() > currentTime) {
      return "future";
    } else {
      return "now";
    }
  }
})).actions((self) => ({}));

// src/data/models/project/project-settings/ProjectSettings.ts
import { types as types17 } from "mobx-state-tree";
var ProjectSettingsModel = types17.model("ProjectSettings").props({
  commentAttachmentsEnabled: types17.optional(types17.boolean, true),
  projectPulseEnabled: types17.optional(types17.boolean, false),
  projectPulseQuestion: types17.optional(types17.maybeNull(types17.string), null),
  projectPulseIcalSchedule: types17.optional(types17.maybeNull(types17.string), null)
}).views((self) => ({})).actions((self) => ({}));

// src/data/models/project/project-analytics/ProjectAnalytics.ts
import { flow as flow8, getParent as getParent2, toGenerator as toGenerator8, types as types19 } from "mobx-state-tree";

// src/data/fields/FloatString.ts
import { types as types18 } from "mobx-state-tree";
var FloatString = types18.custom({
  name: "FloatString",
  fromSnapshot(value) {
    return parseFloat(value);
  },
  toSnapshot(value) {
    return value.toString();
  },
  isTargetType(maybeFloat) {
    return typeof maybeFloat === "number";
  },
  getValidationMessage(snapshot) {
    if (isNaN(parseFloat(snapshot))) {
      return "Invalid number";
    }
    return "";
  }
});

// src/data/models/project/project-analytics/ProjectAnalytics.ts
var TaktMetrics = types19.model("TaktMetrics", {
  plannedTaktMinutes: types19.maybe(types19.map(types19.number)),
  completedTaktMinutes: types19.maybe(types19.map(types19.number)),
  taktPlanDistance: types19.maybe(types19.map(types19.number)),
  cumulativeCompletedTaktMinutes: types19.maybe(types19.map(types19.number)),
  cumulativePlannedTaktMinutes: types19.maybe(types19.map(types19.number)),
  taktCompletedTaktMinutes: types19.maybe(types19.map(types19.map(types19.number)))
});
var GlobalTodoAnalytics = types19.model("GlobalTodoAnalytics", {
  completedTodoCount: types19.maybe(types19.number),
  interruptedTodoCount: types19.maybe(types19.number),
  startedTodoCount: types19.maybe(types19.number),
  scheduleExpectedCompletedTodoCount: types19.maybe(types19.number),
  totalTodoCount: types19.maybe(types19.number)
});
var puseAnalytics = types19.model("PulseAnalytics", {
  pulseByTeamId: types19.maybe(types19.map(FloatString)),
  averageTeamProjectPulse: types19.maybe(FloatString)
});
var ProjectAnalyticsModel = types19.model("ProjectAnalytics").props({
  globalTodoAnalytics: types19.optional(GlobalTodoAnalytics, {}),
  taktMetrics: types19.optional(TaktMetrics, {}),
  pulseAnalytics: types19.optional(puseAnalytics, {})
}).extend(withApi).actions((self) => ({
  loadGlobalTodoAnalytics: flow8(function* () {
    const parent = getParent2(self);
    const response = yield* toGenerator8(self.mestamasterApi.projects.getProjectAnalytics(parent.id));
    if (response.kind === "ok") {
      self.globalTodoAnalytics = response.data.globalTodoAnalytics;
      return response;
    } else {
      console.error(`Error fetching project analytics: ${JSON.stringify(response)}`, []);
      return null;
    }
  }),
  loadSuccessAnalytics: flow8(function* () {
    const parent = getParent2(self);
    const response = yield self.mestamasterApi.projects.fetchProjectSuccessMetrics(parent.id);
    if (response.kind === "ok") {
      self.taktMetrics = response.data.taktMetrics;
    }
  }),
  loadPulseAnalytics: flow8(function* () {
    const parent = getParent2(self);
    const response = yield self.mestamasterApi.projects.fetchPulseAnalytics(parent.id);
    if (response.kind === "ok") {
      self.pulseAnalytics = response.data;
    }
  })
}));

// src/data/models/project/Project.ts
import { now as now4 } from "mobx-utils";

// src/data/models/project/takt/TaktTrain.ts
import { applySnapshot as applySnapshot11, flow as flow11, getParent as getParent3, resolveIdentifier as resolveIdentifier13, toGenerator as toGenerator11, types as types24 } from "mobx-state-tree";

// src/data/models/project/takt/TaktWagon.ts
import { resolveIdentifier as resolveIdentifier10, types as types20 } from "mobx-state-tree";
import { now as now3 } from "mobx-utils";
var TaktWagonModel = types20.model("TaktWagon").props({
  id: types20.identifierNumber,
  startsAt: IsoDate,
  endsAt: IsoDate,
  workPackageId: types20.maybeNull(types20.number),
  taktTrainId: types20.number,
  taktIndex: types20.number,
  name: types20.string,
  todoIds: types20.optional(types20.array(types20.number), [])
}).extend(withRoot).views((self) => ({
  get taktTrain() {
    return resolveIdentifier10(TaktTrainModel, self.root, self.taktTrainId);
  },
  get todos() {
    return self.todoIds.map((id) => {
      return resolveIdentifier10(TodoModel, self.root, id);
    });
  },
  get totalEstimatedDuration() {
    const todos = self.todoIds.map((id) => {
      return resolveIdentifier10(TodoModel, self.root, id);
    });
    return todos.reduce((acc, todo) => acc + ((todo == null ? void 0 : todo.estimatedDuration) || 0), 0);
  }
})).views((self) => ({
  get todosByState() {
    return self.todos.reduce((acc, todo) => {
      if (!todo)
        return acc;
      if (!acc[todo.state])
        acc[todo.state] = [];
      acc[todo.state].push(todo);
      return acc;
    }, {});
  },
  get todosByTeamId() {
    return self.todos.reduce((acc, todo) => {
      if (!todo.teamId) {
        acc["0"] = acc["0"] || [];
        acc["0"].push(todo);
        return acc;
      }
      if (!acc[todo.teamId])
        acc[todo.teamId] = [];
      acc[todo.teamId].push(todo);
      return acc;
    }, {});
  },
  get status() {
    if (!self.todos)
      return "empty";
    if (self.todos.length == 0)
      return "empty";
    let someInProgress = false;
    let anyNotDone = false;
    let interrupted = self.todos.some((todo) => {
      if (!todo)
        return false;
      if (todo.state == "interrupted") {
        return true;
      } else if (todo.state == "started") {
        someInProgress = true;
        return false;
      } else if (!(todo.state == "completed" || todo.state == "skipped")) {
        anyNotDone = true;
        return false;
      }
      return false;
    });
    if (interrupted)
      return "interrupted";
    if (someInProgress)
      return "started";
    if (!anyNotDone)
      return "completed";
    let mobxNow = now3(600);
    return mobxNow.valueOf() > self.endsAt.valueOf() ? "late" : "notStarted";
  }
}));

// src/data/models/project/takt-train-template/TaktTrainTemplate.ts
import {
  types as types23,
  applySnapshot as applySnapshot10,
  flow as flow10,
  toGenerator as toGenerator10,
  destroy as destroy5
} from "mobx-state-tree";

// src/data/models/project/takt-train-template/TaktWagonTemplate.ts
import {
  types as types22,
  flow as flow9,
  toGenerator as toGenerator9,
  applySnapshot as applySnapshot9,
  resolveIdentifier as resolveIdentifier12
} from "mobx-state-tree";

// src/data/models/project/takt-train-template/TaskTemplate.ts
import { resolveIdentifier as resolveIdentifier11, types as types21 } from "mobx-state-tree";
var TaskTemplateModel = types21.model("TaktWagonTemplate").props({
  id: types21.identifierNumber,
  name: types21.string,
  taktWagonTemplateId: types21.number,
  description: types21.optional(types21.string, ""),
  teamId: types21.maybeNull(types21.number),
  estimatedDuration: types21.number,
  order: types21.number,
  wagonOrder: types21.number
}).extend(withRoot).extend(withApi).views((self) => ({
  get teamColor() {
    var _a;
    return self.teamId ? (_a = resolveIdentifier11(TeamModel, self.root, self.teamId)) == null ? void 0 : _a.color : null;
  }
}));

// src/data/models/project/takt-train-template/TaktWagonTemplate.ts
var TaktWagonTemplateModel = types22.model("TaktWagonTemplate").props({
  id: types22.identifierNumber,
  name: types22.string,
  order: types22.number,
  buffer: types22.boolean,
  taskTemplates: types22.optional(types22.array(TaskTemplateModel), []),
  taktTrainTemplateId: types22.number
}).extend(withRoot).extend(withApi).views((self) => ({
  get taskTemplatesArray() {
    return self.taskTemplates.slice().sort((a, b) => a.wagonOrder - b.wagonOrder);
  },
  get totalEstimatedDuration() {
    return Array.from(self.taskTemplates.values()).reduce(
      (acc, taskTemplate) => acc + taskTemplate.estimatedDuration,
      0
    );
  }
})).actions((self) => ({
  addTaskTemplate(taskTemplate) {
    self.taskTemplates.push(taskTemplate);
  }
})).actions((self) => ({
  addTaskTemplates(taskTemplates) {
    taskTemplates.forEach((taskTemplate) => self.addTaskTemplate(taskTemplate));
  }
})).actions((self) => ({
  fetchTaskTemplates: flow9(function* () {
    const response = yield* toGenerator9(self.mestamasterApi.getTaskTemplates(self.id));
    if (response.kind === "ok" && response.data) {
      self.addTaskTemplates(response.data);
      applySnapshot9(self.taskTemplates, response.data);
    } else {
      console.error(`Error fetching task templates: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  createTaskTemplate: flow9(function* (taskTemplate) {
    const response = yield* toGenerator9(
      self.mestamasterApi.createTaskTemplate(self.id, taskTemplate)
    );
    if (response.kind === "ok" && response.data) {
      self.addTaskTemplate(response.data);
    } else {
      console.error(`Error creating task template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  updateTaskTemplate: flow9(function* (taskTemplateId, updatedTaskTemplate) {
    const existingTaskTemplate = resolveIdentifier12(
      TaskTemplateModel,
      self.root,
      taskTemplateId
    );
    const response = yield* toGenerator9(
      self.mestamasterApi.updateTaskTemplate(taskTemplateId, updatedTaskTemplate)
    );
    if (existingTaskTemplate && response.kind === "ok" && response.data) {
      applyPartialSnapshot(existingTaskTemplate, response.data);
    } else {
      console.error(
        `Error updating task template: ${JSON.stringify(response)}`,
        existingTaskTemplate
      );
    }
    return response;
  })
})).actions((self) => ({
  deleteTaskTemplate: flow9(function* (taskTemplateId) {
    const response = yield* toGenerator9(self.mestamasterApi.deleteTaskTemplate(taskTemplateId));
    if (response.kind === "ok") {
      applySnapshot9(self.taskTemplates, self.taskTemplates.filter((task) => task.id !== taskTemplateId));
    } else {
      console.error(`Error delete task template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
}));

// src/data/models/project/takt-train-template/TaktTrainTemplate.ts
var TaktTrainTemplateModel = types23.model("TaktTrainTemplate").props({
  id: types23.identifierNumber,
  projectId: types23.number,
  name: types23.string,
  order: types23.number,
  taktWagonTemplates: types23.optional(types23.map(TaktWagonTemplateModel), {}),
  spaceIds: types23.optional(types23.array(types23.number), [])
}).extend(withRoot).extend(withApi).views((self) => ({
  get taktWagonTemplatesArray() {
    return Array.from(self.taktWagonTemplates.values()).sort((a, b) => a.order - b.order);
  },
  get overallEstimatedDuration() {
    return Array.from(self.taktWagonTemplates.values()).reduce(
      (acc, taktWagonTemplate) => acc + taktWagonTemplate.totalEstimatedDuration,
      0
    );
  }
})).actions((self) => ({
  addTaktWagonTemplate(taktWagonTemplate) {
    const existingTaktWagonTemplate = self.taktWagonTemplates.get(
      taktWagonTemplate.id.toString()
    );
    if (existingTaktWagonTemplate) {
      applyPartialSnapshot(existingTaktWagonTemplate, taktWagonTemplate);
    } else {
      self.taktWagonTemplates.put(taktWagonTemplate);
    }
  },
  garbageCollectWagonTemplates(expectedIds) {
    Array.from(self.taktWagonTemplates.keys()).forEach((id) => {
      if (!expectedIds.includes(parseInt(id))) {
        destroy5(self.taktWagonTemplates.get(id));
      }
    });
  }
})).actions((self) => ({
  addTaktWagonTemplates(taktWagonTemplates, refresh = false) {
    taktWagonTemplates.forEach(
      (taktWagonTemplate) => self.addTaktWagonTemplate(taktWagonTemplate)
    );
    if (refresh) {
      self.garbageCollectWagonTemplates(taktWagonTemplates.map((t) => t.id));
    }
  }
})).actions((self) => ({
  fetchTaktWagonTemplates: flow10(function* (refresh = false) {
    const response = yield* toGenerator10(self.mestamasterApi.getTaktWagonTemplates(self.id));
    if (response.kind === "ok" && response.data) {
      self.addTaktWagonTemplates(response.data, refresh);
    } else {
      console.error(`Error fetching takt wagon templates: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  fetchTaktTrainTemplateSpaces: flow10(function* () {
    const response = yield* toGenerator10(self.mestamasterApi.getTaktTrainsByTemplateId(self.id));
    if (response.kind === "ok" && response.data) {
      const taktTrains = response.data;
      applySnapshot10(
        self.spaceIds,
        taktTrains.map((taktTrain) => taktTrain.spaceId)
      );
    } else {
      console.error(`Error fetching takt train template spaces: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  createTaktWagonTemplate: flow10(function* (newTaktWagonTemplate) {
    const response = yield* toGenerator10(
      self.mestamasterApi.createTaktWagonTemplate(self.id, newTaktWagonTemplate)
    );
    if (response.kind === "ok" && response.data) {
      self.taktWagonTemplatesArray.forEach((wagon) => {
        if (wagon.order >= response.data.order) {
          wagon.order++;
        }
      });
      self.addTaktWagonTemplate(response.data);
    } else {
      console.error(`Error creating takt wagon template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  updateTaktWagonTemplate: flow10(function* (taktWagonTemplateId, updatedTaktWagonTemplate) {
    const existingTaktWagonTemplate = self.taktWagonTemplates.get(taktWagonTemplateId.toString());
    const response = yield* toGenerator10(
      self.mestamasterApi.updateTaktWagonTemplate(taktWagonTemplateId, updatedTaktWagonTemplate)
    );
    if (existingTaktWagonTemplate && response.kind === "ok" && response.data) {
      applyPartialSnapshot(existingTaktWagonTemplate, response.data);
    } else {
      console.error(`Error update takt wagon template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  deleteTaktWagonTemplate: flow10(function* (taktWagonTemplateId) {
    const response = yield* toGenerator10(
      self.mestamasterApi.deleteTaktWagonTemplate(taktWagonTemplateId)
    );
    if (response.kind === "ok") {
      self.taktWagonTemplates.delete(taktWagonTemplateId.toString());
    } else {
      console.error(`Error delete takt wagon template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  afterCreate() {
    self.fetchTaktTrainTemplateSpaces();
    self.fetchTaktWagonTemplates();
  }
}));

// src/data/models/project/takt/TaktTrain.ts
var TaktTrainModel = types24.model("TaktTrain").props({
  id: types24.identifierNumber,
  startsAtTaktIndex: types24.number,
  spaceId: types24.number,
  passageId: types24.maybeNull(types24.number),
  taktWagons: types24.optional(types24.array(types24.late(() => TaktWagonModel)), [])
}).extend(withApi).extend(withRoot).views((self) => ({
  get orderedWagons() {
    return self.taktWagons.slice().sort((a, b) => a.taktIndex - b.taktIndex);
  },
  get space() {
    return resolveIdentifier13(SpaceModel, self.root, self.spaceId);
  },
  get trainTemplate() {
    if (!self.passageId)
      return null;
    return resolveIdentifier13(TaktTrainTemplateModel, self.root, self.passageId);
  }
})).actions((self) => ({
  updateStartsAtTaktIndex: flow11(function* (startsAtTaktIndex) {
    const response = yield* toGenerator11(self.mestamasterApi.updateTaktTrain(self.id, { startsAtTaktIndex }));
    applySnapshot11(self, response.data);
    return response;
  }),
  loadOwnTodos: flow11(function* () {
    const response = yield* toGenerator11(self.mestamasterApi.getTaktTrainTodos(self.id));
    const project = getParent3(getParent3(self));
    project == null ? void 0 : project.addTodos(response.data);
    return response;
  }),
  delete: flow11(function* () {
    const response = yield* toGenerator11(self.mestamasterApi.deleteTaktTrain(self.id));
    if (response.kind === "ok") {
      const parent = getParent3(self, 2);
      parent.removeTaktTrain(self);
    }
    return response;
  })
}));

// src/data/utils/withApiHelpers.ts
var withApiHelpers = (mstInstance) => ({
  fetchPaginatedResource: async (fetchPageCall, onPageLoaded, onCompleted, onPageError) => {
    const dataAccumulator = [];
    let response = await fetchPageCall(1);
    dataAccumulator.push(...response.data);
    if (response.kind === "ok") {
      if (response.data) {
        await onPageLoaded(response);
        let page = 2;
        while (response.meta.pagination.next !== null) {
          response = await fetchPageCall(page);
          if (response.kind === "ok") {
            onPageLoaded(response);
            dataAccumulator.push(...response.data);
            page++;
          } else {
            if (onPageError) {
              onPageError(response);
            } else {
              console.error(`Error fetching paginated resource: ${JSON.stringify(response)}`, []);
            }
            return response;
          }
        }
      }
    } else {
      console.error(`Error fetching todos: ${JSON.stringify(response)}`, []);
    }
    onCompleted && onCompleted(dataAccumulator);
    return { data: dataAccumulator, kind: "ok" };
  }
});

// src/data/models/project/project-memberships/ProjectMembershipRequest.ts
import { applySnapshot as applySnapshot12, flow as flow12, toGenerator as toGenerator12, types as types25 } from "mobx-state-tree";
var RequestUserModel = types25.model("RequestUser").props({
  id: types25.identifierNumber,
  email: types25.string,
  firstName: types25.string,
  lastName: types25.string,
  phoneNumber: types25.maybeNull(types25.string)
});
var ProjectMembershipRequestModel = types25.model("ProjectMembershipRequest").props({
  id: types25.identifierNumber,
  projectId: types25.number,
  userId: types25.number,
  state: types25.enumeration("State", ["pending", "approved", "rejected"]),
  user: types25.maybe(RequestUserModel)
}).extend(withApi).views((self) => ({})).actions((self) => ({
  approve: flow12(function* approve(role) {
    const response = yield* toGenerator12(self.mestamasterApi.projectMemberships.approveProjectMembershipRequest(self.id, role));
    applySnapshot12(self, response.data);
  }),
  reject: flow12(function* reject() {
    const response = yield* toGenerator12(self.mestamasterApi.projectMemberships.rejectProjectMembershipRequest(self.id));
    applySnapshot12(self, response.data);
  })
}));

// src/data/models/project/project-memberships/ProjectMembership.ts
import { resolveIdentifier as resolveIdentifier14, types as types26 } from "mobx-state-tree";
var ProjectMembershipModel = BaseRestModel.named("ProjectMembership").props({
  id: types26.identifierNumber,
  projectId: types26.number,
  userId: types26.number,
  role: types26.enumeration("Role", ["member", "manager", "guest"]),
  createdAt: IsoDate
}).actions(withMestamasterRestActions((self) => self.mestamasterApi.projectMemberships)).views((self) => ({
  get user() {
    return resolveIdentifier14(UserModel, self.root, self.userId);
  }
}));

// src/utils/scheduling.ts
import { RRule } from "rrule";
var getLatestEventFromRRule = (eventRRuleString, starting, howFarBackDays) => {
  const rule = RRule.fromString(eventRRuleString);
  const before = new Date(starting);
  before.setDate(starting.getDate() - (howFarBackDays || 100));
  const lastEvents = rule.between(
    before,
    starting
  );
  return new Date(lastEvents[lastEvents.length - 1]);
};

// src/data/models/project/Project.ts
var UPDATE_VIEWS_MILLISECONDS2 = 6e4;
var TODO_PAGE_SIZE = 10;
var ProjectModel = BaseRestModel.named("Project").props({
  id: types27.identifierNumber,
  companyId: types27.number,
  address: types27.string,
  name: types27.string,
  code: types27.maybe(types27.string),
  startsAt: IsoDate,
  endsAt: types27.maybeNull(IsoDate),
  projectSettings: types27.optional(ProjectSettingsModel, {}, [null, void 0]),
  archived: types27.optional(types27.boolean, false),
  excludedDates: types27.optional(types27.array(IsoDate), []),
  taktDuration: types27.optional(types27.string, "P1D"),
  taktDurationDays: types27.optional(types27.union(types27.number, types27.float), 1),
  todos: types27.optional(types27.map(TodoModel), {}),
  spaces: types27.optional(types27.map(SpaceModel), {}),
  teams: types27.optional(types27.map(TeamModel), {}),
  takts: types27.optional(types27.map(TaktModel), {}),
  taktTrains: types27.optional(types27.map(TaktTrainModel), {}),
  taktTrainTemplates: types27.optional(types27.map(TaktTrainTemplateModel), {}),
  lastSeen: types27.maybeNull(IsoDate),
  nextKeysetTodoPage: types27.maybe(types27.string),
  analytics: types27.optional(ProjectAnalyticsModel, {}),
  projectLoaded: types27.optional(types27.boolean, false),
  projectMemberships: types27.optional(types27.array(ProjectMembershipModel), []),
  projectMembershipInvites: types27.optional(types27.array(ProjectMembershipInviteModel), []),
  projectMembershipRequests: types27.optional(types27.array(ProjectMembershipRequestModel), []),
  latestTodoEventId: types27.maybeNull(types27.number)
}).actions(withSetPropAction).actions(withCollectionTools).actions(withApiHelpers).actions(withMestamasterRestActions((self) => self.mestamasterApi.projects)).actions((self) => ({
  addTodo(todo) {
    self.addOrUpdateEntityToMap(self.todos, todo);
  },
  addTeam(team) {
    self.addOrUpdateEntityToMap(self.teams, team);
  },
  addTakt(takt) {
    self.addOrUpdateEntityToMap(self.takts, takt);
  },
  addSpace(space) {
    self.addOrUpdateEntityToMap(self.spaces, space);
  },
  addTaktTrainTemplate(taktTrainTemplate) {
    self.addOrUpdateEntityToMap(self.taktTrainTemplates, taktTrainTemplate);
  }
})).actions((self) => ({
  addTodos(todos) {
    self.addOrUpdateMultipleEntitiesToMap(self.todos, todos);
  },
  addSpaces(spaces) {
    self.addOrUpdateMultipleEntitiesToMap(self.spaces, spaces);
  },
  addTeams(teams) {
    self.addOrUpdateMultipleEntitiesToMap(self.teams, teams);
  },
  addTakts(takts) {
    self.addOrUpdateMultipleEntitiesToMap(self.takts, takts);
  },
  addTaktTrains(taktTrains) {
    self.addOrUpdateMultipleEntitiesToMap(self.taktTrains, taktTrains);
  },
  addTaktTrainTemplates(taktTrainTemplates) {
    self.addOrUpdateMultipleEntitiesToMap(self.taktTrainTemplates, taktTrainTemplates);
  }
})).views((self) => ({
  get company() {
    return resolveIdentifier15(CompanyModel, self.root, self.companyId);
  },
  get todoEvents() {
    const todos = Array.from(self.todos.values());
    return todos.map((todo) => todo.todoEventArray).flat().sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
  },
  get todosArray() {
    return Array.from(self.todos.values()).sort(
      (a, b) => {
        var _a, _b;
        return ((_a = a.startsAt) == null ? void 0 : _a.getTime()) - ((_b = b.startsAt) == null ? void 0 : _b.getTime()) || a.id - b.id;
      }
    );
  },
  todosBySpaceId(spaceId) {
    return Array.from(self.todos.values()).filter((todo) => todo.spaceId === spaceId);
  },
  get todosByTaktIndexMap() {
    const map = /* @__PURE__ */ new Map();
    Array.from(self.todos.values()).forEach((todo) => {
      var _a;
      if (!todo.takt)
        return;
      if (!map.has(todo.takt)) {
        map.set(todo.takt, []);
      }
      (_a = map.get(todo.takt)) == null ? void 0 : _a.push(todo);
    });
    return map;
  },
  get spaceArray() {
    return Array.from(self.spaces.values());
  },
  get spaceArraySorted() {
    return Array.from(self.spaces.values()).sort((a, b) => a.order - b.order);
  },
  get teamArray() {
    return Array.from(self.teams.values());
  },
  get teamArraySorted() {
    return Array.from(self.teams.values()).sort((a, b) => a.order - b.order);
  },
  getTeamById(teamId) {
    return self.teams.get(teamId.toString());
  },
  getTeamNameById(teamId) {
    var _a;
    return ((_a = self.teams.get(teamId.toString())) == null ? void 0 : _a.name) || "";
  },
  get teamColors() {
    return Array.from(self.teams.values()).reduce((acc, team) => {
      if (team.color !== null) {
        acc[team.id.toString()] = team.color;
      }
      return acc;
    }, {});
  },
  get taktArray() {
    return Array.from(self.takts.values()).sort((a, b) => a.taktIndex - b.taktIndex);
  },
  get taktsByIndex() {
    const array = [];
    Array.from(self.takts.values()).forEach((takt) => {
      array[takt.taktIndex] = takt;
    });
    return array;
  },
  get taktTrainArray() {
    return Array.from(self.taktTrains.values()).sort(
      (a, b) => a.startsAtTaktIndex - b.startsAtTaktIndex
    );
  },
  getTaktRangeArray(startTaktIndex, endTaktIndex) {
    return Array.from(self.takts.values()).filter(
      (takt) => takt.taktIndex >= startTaktIndex && takt.taktIndex <= endTaktIndex
    );
  },
  taktTrainsForSpace(spaceId) {
    return Array.from(self.taktTrains.values()).filter((train) => train.spaceId === spaceId);
  },
  get trainsBySpaceId() {
    return Array.from(self.taktTrains.values()).reduce((acc, train) => {
      if (train.spaceId) {
        if (acc[train.spaceId]) {
          acc[train.spaceId].push(train);
        } else {
          acc[train.spaceId] = [train];
        }
      }
      return acc;
    }, {});
  },
  get taktTrainTemplateArray() {
    return Array.from(self.taktTrainTemplates.values());
  },
  get taktTrainTemplateArraySorted() {
    return Array.from(self.taktTrainTemplates.values()).sort((a, b) => a.order - b.order);
  },
  getSpace(spaceId) {
    return self.spaces.get(spaceId);
  },
  get currentTakt() {
    return Array.from(self.takts.values()).filter((x) => {
      var _a;
      return x.endsAt.valueOf() > now4(UPDATE_VIEWS_MILLISECONDS2) && ((_a = x.startsAt) == null ? void 0 : _a.valueOf()) < now4(UPDATE_VIEWS_MILLISECONDS2);
    })[0];
  },
  get notCompletedTodosArray() {
    return Array.from(self.todos.values()).filter((todo) => todo.state != "completed").sort((a, b) => {
      var _a, _b;
      return ((_a = a.startsAt) == null ? void 0 : _a.getTime()) - ((_b = b.startsAt) == null ? void 0 : _b.getTime());
    });
  },
  get upcomingAndRecentlyCompletedArray() {
    return Array.from(self.todos.values()).filter(
      (todo) => {
        var _a;
        return todo.state != "completed" && todo.state != "skipped" || todo.completedAt && self.lastSeen && todo.completedAt.valueOf() > ((_a = self.lastSeen) == null ? void 0 : _a.getTime());
      }
    ).sort((a, b) => {
      var _a, _b;
      return ((_a = a.startsAt) == null ? void 0 : _a.getTime()) - ((_b = b.startsAt) == null ? void 0 : _b.getTime());
    });
  },
  get projectAnalytics() {
    if (self.analytics.globalTodoAnalytics.totalTodoCount === void 0)
      return void 0;
    return getSnapshot3(self.analytics);
  },
  get TodoTeamTaktMatrix() {
    const matrix = /* @__PURE__ */ new Map();
    Array.from(self.todos.values()).forEach((todo) => {
      var _a;
      if (!todo.takt)
        return;
      const teamId = todo.teamId || 0;
      const takt = todo.takt;
      if (!matrix.has(takt)) {
        matrix.set(takt, /* @__PURE__ */ new Map());
      }
      const teamMatrix = matrix.get(takt);
      if (teamMatrix) {
        if (!teamMatrix.has(teamId)) {
          teamMatrix.set(teamId, []);
        }
        (_a = teamMatrix.get(teamId)) == null ? void 0 : _a.push(todo);
      }
    });
    return matrix;
  },
  get standaloneTodos() {
    return Array.from(self.todos.values()).filter((todo) => !todo.taktWagonId);
  },
  standaloneTodosForSpace(spaceId) {
    return Array.from(self.todos.values()).filter(
      (todo) => !todo.taktWagonId && todo.spaceId === spaceId
    );
  }
})).views((self) => ({
  get firstTakt() {
    return self.taktsByIndex[1];
  },
  get lastTakt() {
    return self.taktsByIndex[self.taktsByIndex.length - 1];
  },
  get latestTodoEvent() {
    if (!self.latestTodoEventId)
      return void 0;
    return self.todoEvents.find((event) => event.id === self.latestTodoEventId);
  },
  get scheduledCutoff() {
    if (self.currentTakt)
      return self.currentTakt.endsAt.valueOf();
    return now4(UPDATE_VIEWS_MILLISECONDS2) + 10 * 60 * 60;
  },
  getTaktStatus(taktIndex) {
    const takt = self.taktsByIndex[taktIndex];
    if (!takt)
      return "uncompleted";
    const taktTodos = self.todosByTaktIndexMap.get(taktIndex) || [];
    let allCompleted = true;
    for (const item of taktTodos) {
      if (item.state === "interrupted") {
        return "interrupted";
      }
      if (item.state !== "completed") {
        allCompleted = false;
      }
    }
    if (self.currentTakt && allCompleted)
      return takt.startsAt.valueOf() > now4(UPDATE_VIEWS_MILLISECONDS2) ? "uncompleted" : "completed";
    return allCompleted ? "completed" : "uncompleted";
  },
  getCompletedPercentageByTaktIndex(taktIndex) {
    const todos = self.todosByTaktIndexMap.get(taktIndex) || [];
    if (todos.length === 0)
      return 0;
    const completedTodos = todos.filter((todo) => todo.state === "completed");
    return completedTodos.length / todos.length * 100;
  }
})).views((self) => ({
  get taktStatusMap() {
    const map = /* @__PURE__ */ new Map();
    self.taktArray.forEach((takt) => {
      map.set(takt.taktIndex, self.getTaktStatus(takt.taktIndex));
    });
    return map;
  },
  get blockedTodosArray() {
    return Array.from(self.todos.values()).filter((todo) => todo.state == "interrupted").sort((a, b) => {
      const aTime = a.dueAt ? a.dueAt.getTime() : 0;
      const bTime = b.dueAt ? b.dueAt.getTime() : 0;
      return aTime - bTime;
    });
  },
  get lateTodosArray() {
    return self.upcomingAndRecentlyCompletedArray.filter((todo) => {
      return todo.dueAt && todo.dueAt.valueOf() <= now4(UPDATE_VIEWS_MILLISECONDS2) && todo.state != "interrupted";
    });
  },
  get scheduledNotInterruptedTodosArray() {
    return self.upcomingAndRecentlyCompletedArray.filter((todo) => {
      return todo.dueAt && todo.dueAt.valueOf() > now4(UPDATE_VIEWS_MILLISECONDS2) && todo.dueAt.valueOf() <= self.scheduledCutoff && todo.state != "interrupted";
    });
  },
  get upcomingNotInterruptedTodosArray() {
    return self.upcomingAndRecentlyCompletedArray.filter((todo) => {
      return todo.dueAt && todo.dueAt.valueOf() > now4(UPDATE_VIEWS_MILLISECONDS2) && todo.dueAt.valueOf() > self.scheduledCutoff && todo.state != "interrupted";
    });
  },
  get recentlyCompletedTodosArray() {
    return self.todosArray.filter((todo) => {
      return todo.completedAt && todo.completedAt.valueOf() > now4(UPDATE_VIEWS_MILLISECONDS2) - 5 * 60 * 60;
    });
  }
})).views((self) => ({
  blockedTodosArrayForUser(user) {
    return self.blockedTodosArray.filter(
      (todo) => todo.teamId && user.teamMembershipsTeamIdArray.includes(todo.teamId)
    );
  },
  lateTodosArrayForUser(user) {
    return self.lateTodosArray.filter(
      (todo) => todo.teamId && user.teamMembershipsTeamIdArray.includes(todo.teamId)
    );
  },
  scheduledNotInterruptedTodosArrayForUser(user) {
    return self.scheduledNotInterruptedTodosArray.filter(
      (todo) => todo.teamId && user.teamMembershipsTeamIdArray.includes(todo.teamId)
    );
  },
  upcomingTodosArrayForUser(user) {
    return self.upcomingNotInterruptedTodosArray.filter(
      (todo) => todo.teamId && user.teamMembershipsTeamIdArray.includes(todo.teamId)
    );
  },
  get pendingMembershipRequests() {
    return self.projectMembershipRequests.filter((request) => request.state === "pending");
  },
  get pendingMembershipInvites() {
    return self.projectMembershipInvites.filter((invite) => invite.state === "pending");
  },
  getPendingMembershipInvitesByTeamId(teamId) {
    if (!teamId)
      return [];
    return self.projectMembershipInvites.filter((invite) => invite.state === "pending" && invite.teamIds.includes(teamId));
  }
})).actions((self) => ({
  setLatestTodoEvent(event) {
    self.latestTodoEventId = event.id;
  },
  setProjectLoaded(state) {
    self.projectLoaded = state;
  },
  removeTaktTrain(train) {
    detach4(train);
    self.taktTrains.delete(train.id.toString());
    destroy7(train);
  }
})).actions((self) => ({
  fetchTeamAnalytics: flow14(function* () {
    const response = yield* toGenerator14(self.mestamasterApi.getTeamsAnalytics(self.id));
    if (response.kind === "ok" && response.data) {
      self.teams.forEach((team) => {
        applyPartialSnapshot(team.analytics, response.data[team.id]);
      });
    } else {
      console.error(`Error fetching team analytics: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  createTaktTrain: flow14(function* (spaceId, startsAtTaktIndex, taktTrainTemplateId) {
    var _a;
    const newTaktTrain = {
      spaceId,
      startsAtTaktIndex,
      taktTrainTemplateId
    };
    const response = yield* toGenerator14(
      self.mestamasterApi.createTaktTrain(self.id, newTaktTrain)
    );
    if (response.kind === "ok") {
      self.addTaktTrains([response.data]);
      (_a = self.taktTrains.get(response.data.id.toString())) == null ? void 0 : _a.loadOwnTodos();
    }
  }),
  fetchTodo: flow14(function* (todoId) {
    const response = yield* toGenerator14(self.mestamasterApi.getTodoDetails(parseInt(todoId)));
    if (response.kind === "ok") {
      if (response.data.projectId == self.id) {
        self.addTodo(response.data);
      }
    }
    return null;
  }),
  fetchTodos: flow14(function* (filters, resetStore) {
    const response = yield* toGenerator14(self.mestamasterApi.getProjectTodos(self.id, filters));
    if (response.kind === "ok") {
      if (response.data) {
        if (resetStore) {
          const todos = response.data.reduce((acc, todo) => {
            acc[todo.id] = todo;
            return acc;
          }, {});
          applySnapshot14(self.todos, todos);
          self.setProp("nextKeysetTodoPage", response.meta.pagination.next);
        } else {
          self.addTodos(response.data);
        }
      }
    } else {
      console.error(`Error fetching todos: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  createStandaloneTodo: flow14(function* (newTodo) {
    const result = yield* toGenerator14(self.mestamasterApi.createProjectStandaloneTodo(self.id, newTodo));
    if (result.kind === "ok" && result.data) {
      self.addTodo(result.data);
    }
    return result;
  })
})).actions((self) => ({
  resetAndFetchInitialTodosForUser: flow14(function* () {
    self.nextKeysetTodoPage = void 0;
    let response = yield self.fetchTodos(
      {
        items: TODO_PAGE_SIZE,
        after: void 0,
        complete: false,
        own: true
      },
      true
    );
    if ((response == null ? void 0 : response.kind) === "ok") {
      self.nextKeysetTodoPage = response.meta.pagination.next;
    }
    return response;
  }),
  loadMoreOwnIncompleteTodos: flow14(function* () {
    const response = yield self.fetchTodos({
      page: self.nextKeysetTodoPage,
      items: TODO_PAGE_SIZE,
      own: true,
      complete: false
    });
    self.nextKeysetTodoPage = response.meta.pagination.next;
    return response;
  }),
  loadAllTodos: flow14(function* () {
    let loadedTodoIds = [];
    let nextPage = void 0;
    while (true) {
      const response = yield self.fetchTodos(
        {
          page: nextPage,
          items: 200
        },
        false
      );
      if (response.kind === "ok") {
        loadedTodoIds.push(...response.data.map((todo) => todo.id));
        nextPage = response.meta.pagination.next;
      } else {
        console.error(`Error fetching all todos: ${JSON.stringify(response)}`, []);
        break;
      }
      if (!response.meta.pagination.next) {
        self.cleanupMap(self.todos, loadedTodoIds);
        break;
      }
    }
  }),
  fetchAllSpaces: flow14(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.getProjectSpaces(self.id, page, 100),
      (result) => {
        self.addSpaces(result.data);
      },
      (loadedResources) => {
        const loadedSpaceIds = loadedResources.map((space) => space.id);
        self.cleanupMap(self.spaces, loadedSpaceIds);
      }
    );
    return response;
  }),
  fetchAllTeams: flow14(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.getProjectTeams(self.id, page, 100),
      (result) => {
        self.addTeams(result.data);
      },
      (loadedResources) => {
        const loadedTeamIds = loadedResources.map((team) => team.id);
        self.cleanupMap(self.teams, loadedTeamIds);
      }
    );
    return response;
  }),
  fetchAllTakts: flow14(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.getProjectTakts(self.id, page, 100),
      (result) => {
        self.addTakts(result.data);
      }
    );
    return response;
  }),
  fetchAllTaktTrains: flow14(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.getTaktTrains(self.id, page, 20),
      (result) => {
        self.addTaktTrains(result.data);
      },
      (loadedResources) => {
        const loadedTaktTrainIds = loadedResources.map((train) => train.id);
        self.cleanupMap(self.taktTrains, loadedTaktTrainIds);
      }
    );
    return response;
  }),
  fetchAllTaktTrainTemplates: flow14(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.getTaktTrainTemplates(self.id),
      (result) => {
        self.addTaktTrainTemplates(result.data);
      },
      (loadedResources) => {
        const loadedTaktTrainTemplateIds = loadedResources.map((template) => template.id);
        self.cleanupMap(self.taktTrainTemplates, loadedTaktTrainTemplateIds);
      }
    );
    return response;
  }),
  updateSelf: flow14(function* () {
    self.latestTodoEventId = null;
    const response = yield self.mestamasterApi.projects.getProject(self.id.toString());
    if (response.kind === "ok") {
      applyPartialSnapshot(self, response.data);
    }
  }),
  handleTodoEvent: flow14(function* (data) {
    var _a;
    (_a = self.todos.get(data.todoId.toString())) == null ? void 0 : _a.loadAll();
  })
})).actions((self) => ({
  createSpace: flow14(function* (newSpace) {
    const result = yield* toGenerator14(self.mestamasterApi.createProjectSpace(self.id, newSpace));
    if (result.kind === "ok" && result.data) {
      self.addSpace(result.data);
    }
    return result;
  }),
  createTeam: flow14(function* (newTeam) {
    const result = yield* toGenerator14(self.mestamasterApi.createTeam(self.id, newTeam));
    if (result.kind === "ok" && result.data) {
      self.addTeam(result.data);
    } else {
      console.error(`Error creating team: ${JSON.stringify(result)}`, []);
    }
    return result;
  }),
  createTaktTrainTemplate: flow14(function* (newTaktTrainTemplate) {
    const result = yield* toGenerator14(
      self.mestamasterApi.createTaktTrainTemplate(self.id, newTaktTrainTemplate)
    );
    if (result.kind === "ok" && result.data) {
      self.addTaktTrainTemplate(result.data);
    } else {
      console.error(`Error creating takt train template: ${JSON.stringify(result)}`, []);
    }
    return result;
  }),
  cloneTaktTrainTemplate: flow14(function* (taktTrainTemplateToBeClonedId, clonedTrainData) {
    const cloningTaktTemplate = self.taktTrainTemplates.get(
      taktTrainTemplateToBeClonedId.toString()
    );
    const result = yield* toGenerator14(
      self.mestamasterApi.cloneTaktTrainTemplate(taktTrainTemplateToBeClonedId, clonedTrainData)
    );
    if (cloningTaktTemplate && result.kind === "ok" && result.data) {
      applySnapshot14(
        cloningTaktTemplate.spaceIds,
        cloningTaktTemplate.spaceIds.filter(
          (spaceId) => !clonedTrainData.spaceIds.includes(spaceId)
        )
      );
      self.addTaktTrainTemplate(result.data);
    } else {
      console.error(`Error cloning take train template: ${JSON.stringify(result)}`, []);
    }
    return result;
  })
})).actions((self) => ({
  updateSpace: flow14(function* (updatedSpaceId, updatedSpace) {
    const existingSpace = self.spaces.get(updatedSpaceId.toString());
    const response = yield* toGenerator14(
      self.mestamasterApi.updateSpace(updatedSpaceId, updatedSpace)
    );
    if (existingSpace && response.kind === "ok" && response.data) {
      applyPartialSnapshot(existingSpace, response.data);
    } else {
      console.error(`Error update space: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  updateTeam: flow14(function* (updatedTeamId, updatedTeam) {
    const existingTeam = self.teams.get(updatedTeamId.toString());
    const response = yield* toGenerator14(
      self.mestamasterApi.updateTeam(updatedTeamId, updatedTeam)
    );
    if (existingTeam && response.kind === "ok" && response.data) {
      applyPartialSnapshot(existingTeam, response.data);
    } else {
      console.error(`Error updating team ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  updateTaktTrainTemplate: flow14(function* (taktTrainTemplateId, updatedTaktTrainTemplate) {
    const existingTaktTrainTemplate = self.taktTrainTemplates.get(taktTrainTemplateId.toString());
    const response = yield* toGenerator14(
      self.mestamasterApi.updateTaktTrainTemplate(taktTrainTemplateId, updatedTaktTrainTemplate)
    );
    if (existingTaktTrainTemplate && response.kind === "ok" && response.data) {
      applyPartialSnapshot(existingTaktTrainTemplate, response.data);
    } else {
      console.error(`Error update takt train template: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((self) => ({
  updateSpaceOrder: flow14(function* (spaceId, newOrder, oldOrder) {
    const response = yield* toGenerator14(self.updateSpace(spaceId, { order: newOrder }));
    if (response.kind === "ok" && response.data) {
      self.spaceArraySorted.filter((space) => space.id !== spaceId).forEach((space) => {
        if (space.order >= newOrder && space.order < oldOrder) {
          space.order++;
        } else if (space.order <= newOrder && space.order > oldOrder) {
          space.order--;
        }
      });
    }
    return response;
  }),
  updateTaktTrainTemplateOrder: flow14(function* (taktTrainTemplateId, newOrder, oldOrder) {
    const response = yield* toGenerator14(
      self.updateTaktTrainTemplate(taktTrainTemplateId, { order: newOrder })
    );
    if (response.kind === "ok" && response.data) {
      self.taktTrainTemplateArray.filter((taktTrainTemplate) => taktTrainTemplate.id !== taktTrainTemplateId).forEach((taktTrainTemplate) => {
        if (taktTrainTemplate.order >= newOrder && taktTrainTemplate.order < oldOrder) {
          taktTrainTemplate.order++;
        } else if (taktTrainTemplate.order <= newOrder && taktTrainTemplate.order > oldOrder) {
          taktTrainTemplate.order--;
        }
      });
    }
    return response;
  })
})).actions((self) => ({
  deleteSpace: flow14(function* (spaceId) {
    const response = yield* toGenerator14(self.mestamasterApi.deleteSpace(spaceId));
    if (response.kind === "ok") {
      self.spaces.delete(spaceId.toString());
      self.spaces.forEach((space) => {
        const spaceSortedIndex = self.spaceArraySorted.findIndex(
          (sortedSpace) => sortedSpace.id === space.id
        );
        space.order = spaceSortedIndex > -1 ? spaceSortedIndex : 0;
      });
    } else {
      console.error(`Error delete space: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  deleteTeam: flow14(function* (teamId) {
    const response = yield* toGenerator14(self.mestamasterApi.deleteTeam(teamId));
    if (response.kind === "ok") {
      self.teams.delete(teamId.toString());
      self.teams.forEach((team) => {
        const teamSortedIndex = self.teamArraySorted.findIndex(
          (sortedTeam) => sortedTeam.id === team.id
        );
        team.order = teamSortedIndex > -1 ? teamSortedIndex : 0;
      });
    } else {
      console.error(`Error delete team: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  deleteTaktTrainTemplate: flow14(function* (taktTrainTemplateId) {
    const response = yield* toGenerator14(
      self.mestamasterApi.deleteTaktTrainTemplate(taktTrainTemplateId)
    );
    if (response.kind === "ok") {
      self.taktTrainTemplates.delete(taktTrainTemplateId.toString());
    } else {
      console.error(`Error delete takt train template ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  fetchProjectMembershipRequests: flow14(function* () {
    const response = yield* toGenerator14(
      self.mestamasterApi.projectMemberships.fetchProjectMembershipRequests(self.id)
    );
    if (response.kind === "ok" && response.data) {
      applySnapshot14(self.projectMembershipRequests, response.data);
    } else {
      console.error(`Error fetching project membership requests: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  fetchProjectMembershipInvites: flow14(function* () {
    const response = yield* toGenerator14(
      self.mestamasterApi.projectMemberships.fetchProjectMembershipInvites(self.id)
    );
    if (response.kind === "ok" && response.data) {
      applySnapshot14(self.projectMembershipInvites, response.data);
    } else {
      console.error(`Error fetching project membership invites: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  fetchProjectMemberships: flow14(function* () {
    const response = yield* toGenerator14(
      self.mestamasterApi.projectMemberships.fetchProjectMemberships(self.id)
    );
    if (response.kind === "ok" && response.data) {
      applySnapshot14(self.projectMemberships, response.data);
    } else {
      console.error(`Error fetching project memberships: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  inviteToProject: flow14(function* (data) {
    const response = yield* toGenerator14(
      self.mestamasterApi.projectMemberships.inviteToProject({
        projectId: self.id,
        ...data
      })
    );
    if (response.kind === "ok") {
      self.projectMembershipInvites.push(response.data);
    } else {
      console.error(`Error inviting to project: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).views((self) => ({
  shouldAskPulse(lastUserPulse) {
    if (!self.projectSettings)
      return false;
    if (!self.projectSettings.projectPulseEnabled)
      return false;
    if (!self.projectSettings.projectPulseIcalSchedule)
      return false;
    const staggeredNow = new Date(now4(UPDATE_VIEWS_MILLISECONDS2 * 1e3));
    if (!lastUserPulse)
      return false;
    const latestScheduleEvent = getLatestEventFromRRule(
      self.projectSettings.projectPulseIcalSchedule,
      staggeredNow,
      60
    );
    if (!latestScheduleEvent) {
      console.log("No schedule event found");
      return false;
    }
    return lastUserPulse.getTime() < latestScheduleEvent.getTime() && staggeredNow.getTime() > latestScheduleEvent.getTime();
  }
})).actions((self) => ({
  refresh: flow14(function* () {
    self.setProjectLoaded(false);
    yield self.updateSelf();
    self.root.usersStore.fetchProjectUsers(self.id);
    yield self.fetchProjectMemberships();
    yield self.fetchAllTeams();
    yield self.fetchAllTakts();
    yield self.fetchAllSpaces();
    self.setProjectLoaded(true);
  })
}));

// src/data/stores/index.ts
var stores_exports = {};
__export(stores_exports, {
  AuthenticationStoreModel: () => AuthenticationStoreModel,
  CompaniesStoreModel: () => CompaniesStoreModel,
  ProjectsStoreModel: () => ProjectsStoreModel,
  RootStoreModel: () => RootStoreModel,
  SSOProvider: () => SSOProvider,
  UsersStoreModel: () => UsersStoreModel
});

// src/data/stores/UsersStore.ts
import {
  applySnapshot as applySnapshot15,
  flow as flow15,
  toGenerator as toGenerator15,
  types as types28
} from "mobx-state-tree";
var UsersStoreModel = types28.model("UsersStore").props({
  users: types28.optional(types28.map(UserModel), {})
}).extend(withApi).extend(withRoot).views((self) => ({
  get usersArray() {
    return Array.from(self.users.values());
  },
  get selectedProjectUsers() {
    var _a;
    const projectMemberships = ((_a = self.root.projectsStore.selectedProject) == null ? void 0 : _a.projectMemberships) || [];
    return projectMemberships.map((membership) => self.users.get(membership.userId.toString())).filter(Boolean) || [];
  }
})).actions((self) => ({
  addUser(user) {
    const existingUser = self.users.get(user.id.toString());
    if (existingUser) {
      applySnapshot15(existingUser, user);
    } else {
      self.users.put(user);
    }
  }
})).actions((self) => ({
  addUsers(users) {
    users.forEach((user) => {
      self.addUser(user);
    });
  }
})).actions((self) => ({
  fetchProjectUsers: flow15(function* (projectId) {
    const result = yield* toGenerator15(self.mestamasterApi.users.fetchProjectUsers(projectId));
    if (result.kind === "ok") {
      self.addUsers(result.data);
    }
  })
}));

// src/data/stores/CompaniesStore.ts
import {
  applySnapshot as applySnapshot16,
  flow as flow16,
  toGenerator as toGenerator16,
  types as types29
} from "mobx-state-tree";
var CompaniesStoreModel = types29.model("CompaniesStore").props({
  companies: types29.optional(types29.map(CompanyModel), {}),
  selectedCompany: types29.maybe(types29.reference(CompanyModel))
}).extend(withApi).views((self) => ({
  get companiesArray() {
    return Array.from(self.companies.values());
  }
})).actions((self) => ({
  addCompany(company) {
    const existingCompany = self.companies.get(company.id.toString());
    if (existingCompany) {
      applySnapshot16(existingCompany, company);
    } else {
      self.companies.put(company);
    }
  }
})).actions((self) => ({
  addCompanies(companies) {
    companies.forEach((company) => self.addCompany(company));
  }
})).actions((self) => ({
  fetchCompanies: flow16(function* () {
    const result = yield* toGenerator16(self.mestamasterApi.companies.getCompanies());
    if (result.kind === "ok") {
      self.addCompanies(result.data);
    }
    return result;
  })
})).actions((self) => ({
  selectCompany: flow16(function* (companyId) {
    if (!self.companies.has(companyId)) {
      const result = yield* toGenerator16(self.mestamasterApi.companies.getCompany(companyId));
      if (result.kind === "ok") {
        self.addCompany(result.data);
      } else {
        console.error("Company not found");
        self.selectedCompany = void 0;
        return;
      }
    }
    self.selectedCompany = self.companies.get(companyId);
  }),
  deselectCompany() {
    self.selectedCompany = void 0;
  }
}));

// src/data/stores/ProjectsStore.ts
import { types as types30, flow as flow17, resolveIdentifier as resolveIdentifier16, toGenerator as toGenerator17 } from "mobx-state-tree";
import { now as now5 } from "mobx-utils";
var ProjectsStoreModel = types30.model("ProjectsStore").props({
  projects: types30.optional(types30.map(ProjectModel), {}),
  selectedProject: types30.maybe(types30.reference(ProjectModel))
}).extend(withApi).actions(withApiHelpers).actions(withCollectionTools).views((self) => ({
  get projectsArray() {
    return Array.from(self.projects.values()).sort((a, b) => {
      var _a, _b;
      if (!a.endsAt || !b.endsAt) {
        return 0;
      }
      return ((_a = b.endsAt) == null ? void 0 : _a.valueOf()) - ((_b = a.endsAt) == null ? void 0 : _b.valueOf());
    });
  }
})).views((self) => ({
  get liveProjects() {
    return self.projectsArray.filter((project) => project.endsAt && !project.archived && project.endsAt.valueOf() > now5(6e4));
  },
  get oldProjects() {
    return self.projectsArray.filter((project) => project.endsAt && !project.archived && (project.endsAt === null || project.endsAt.valueOf() + 2 * 30 * 24 * 60 * 60 * 60 < now5(6e4)));
  },
  get archivedProjects() {
    return self.projectsArray.filter((project) => project.archived);
  },
  getProjectsByCompanyId(companyId) {
    return self.projectsArray.filter((project) => project.companyId === companyId);
  },
  getLiveProjectsByCompanyId(companyId) {
    return self.projectsArray.filter((project) => project.companyId === companyId && project.endsAt && !project.archived && project.endsAt.valueOf() > now5(6e4));
  },
  getOldProjectsByCompanyId(companyId) {
    return self.projectsArray.filter((project) => project.companyId === companyId && project.endsAt && !project.archived && (project.endsAt === null || project.endsAt.valueOf() + 2 * 30 * 24 * 60 * 60 * 60 < now5(6e4)));
  },
  getArchivedProjectsByCompanyId(companyId) {
    return self.projectsArray.filter((project) => project.companyId === companyId && project.archived);
  }
})).actions((self) => ({
  addProject(project) {
    const existingProject = self.projects.get(project.id.toString());
    if (existingProject) {
      applyPartialSnapshot(existingProject, project);
    } else {
      self.projects.put(project);
    }
  }
})).actions((self) => ({
  addProjects(projects) {
    projects.forEach((project) => self.addProject(project));
  }
})).actions((self) => ({
  fetchProjects: flow17(function* () {
    const response = yield self.fetchPaginatedResource(
      async (page) => await self.mestamasterApi.projects.list(100, page),
      (result) => {
        self.addProjects(result.data);
      },
      (loadedResources) => {
        const loadedProjectIds = loadedResources.map((project) => project.id);
        self.cleanupMap(self.projects, loadedProjectIds);
      }
    );
    return response;
  }),
  handleUpdatedTodoEvent: flow17(function* (data) {
    const todo = resolveIdentifier16(TodoModel, self, data.todoId);
    if (!todo) {
      console.error("Todo not found");
      return;
    }
    todo.loadDetails();
    const project = self.projects.get(todo.projectId);
    project == null ? void 0 : project.setLatestTodoEvent(data);
  }),
  handleChecklistItemUpdate: flow17(function* (data) {
    const checklist_item = resolveIdentifier16(ChecklistItemModel, self, data.id);
    if (!checklist_item) {
      return;
    }
    checklist_item.updateSelf(data);
  })
})).actions((self) => ({
  selectProject: flow17(function* (project_id) {
    if (!self.projects.has(project_id)) {
      const result = yield* toGenerator17(self.mestamasterApi.projects.getProject(project_id));
      if (result.kind === "ok") {
        self.addProject(result.data);
        self.selectedProject = self.projects.get(project_id);
      }
    }
    if (!self.projects.has(project_id)) {
      console.error("Project not found");
      self.selectedProject = void 0;
      return;
    }
    self.selectedProject = self.projects.get(project_id);
    if (self.selectedProject) {
      self.selectedProject.refresh();
    }
  }),
  deselectProject() {
    self.selectedProject = void 0;
  },
  listenToProject(projectId) {
    var _a, _b;
    (_a = self.cableConnection) == null ? void 0 : _a.subscribe(
      "V3ProjectTodoEventsChannel",
      { project_id: projectId },
      (data) => {
        self.handleUpdatedTodoEvent(data.data);
      }
    );
    (_b = self.cableConnection) == null ? void 0 : _b.subscribe(
      "V3ProjectChecklistItemEventsChannel",
      { project_id: projectId },
      (data) => {
        self.handleChecklistItemUpdate(data.data);
      }
    );
    console.debug("Subscribed events for project " + projectId);
  }
})).actions((self) => ({
  createProject: flow17(function* (companyId, newProject) {
    const result = yield* toGenerator17(
      self.mestamasterApi.projects.createProject(companyId, newProject)
    );
    if (result.kind === "ok") {
      self.addProject(result.data);
    }
    return result;
  }),
  toggleArchiveProject: flow17(function* (projectId, archived) {
    const result = yield* toGenerator17(
      self.mestamasterApi.projects.updateProject(projectId, { archived })
    );
    const existingProject = self.projects.get(projectId.toString());
    if (result.kind === "ok" && existingProject) {
      applyPartialSnapshot(existingProject, result.data);
    }
    return result;
  }),
  createProjectMembershipRequest: flow17(function* (code) {
    const result = yield* toGenerator17(
      self.mestamasterApi.projectMemberships.createProjectMembershipRequest(code)
    );
    return result;
  })
}));

// src/data/stores/AuthenticationStore.ts
import { types as types31, flow as flow18, applySnapshot as applySnapshot17, getEnv as getEnv2, toGenerator as toGenerator18 } from "mobx-state-tree";

// src/services/sso/oauth.ts
import cryptoJs from "crypto-js";
function generateCodeVerifier() {
  const length = 96;
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomValues = new Uint8Array(length);
  randomValues.forEach((_value, index) => {
    randomValues[index] = Math.floor(Math.random() * charset.length);
  });
  let result = "";
  for (let i = 0; i < length; i++) {
    result += charset.charAt(randomValues[i] % charset.length);
  }
  return result;
}
var EncodeBase64URL = (str) => {
  return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
};
async function generateCodeChallenge(oauthCodeVerifier) {
  const utf8Array = cryptoJs.enc.Utf8.parse(oauthCodeVerifier);
  const hashed = cryptoJs.SHA256(utf8Array);
  const hashBase64 = cryptoJs.enc.Base64.stringify(hashed);
  return EncodeBase64URL(hashBase64.split("=")[0]);
}
var getInitiationPKCEUrl = async (settings, redirectUri, oauthCodeVerifier) => {
  const state = Math.random().toString(36).substring(7);
  const challenge = await generateCodeChallenge(oauthCodeVerifier);
  const url = `${settings.authorizationEndpoint}?response_type=code&client_id=${settings.clientId}&redirect_uri=${redirectUri}&code_challenge=${challenge}&code_challenge_method=S256&state=${state}&scope=${settings.scope}`;
  return url;
};

// src/data/stores/AuthenticationStore.ts
var SSOProvider = types31.model("SSOProvider").props({
  provider: types31.string,
  oauth: types31.model({
    authorizationEndpoint: types31.string,
    tokenEndpoint: types31.string,
    clientId: types31.string,
    scope: types31.string
  })
});
var AuthenticationStoreModel = types31.model("AuthenticationStore").props({
  authenticatedUser: types31.maybeNull(AuthenticatedUserModel),
  authenticatedDeviceId: types31.maybeNull(types31.number),
  ssoProviders: types31.optional(types31.map(SSOProvider), {}),
  oauthCodeVerifier: types31.maybe(types31.string)
}).extend(withApi).extend(withRoot).views((store) => ({
  get isAuthenticated() {
    return !!store.authenticatedUser;
  },
  get isOnboarded() {
    var _a, _b;
    return !!((_a = store.authenticatedUser) == null ? void 0 : _a.gdprConsent) && !!((_b = store.authenticatedUser) == null ? void 0 : _b.onboarded);
  }
})).actions((store) => ({
  setAuthenticatedDeviceId(id) {
    store.authenticatedDeviceId = id;
  },
  setAuthenticatedUser(user) {
    if (user == null) {
      store.authenticatedUser = null;
      return;
    }
    if (store.authenticatedUser) {
      applySnapshot17(store.authenticatedUser, user);
    } else {
      store.authenticatedUser = user;
    }
  },
  async apiHasValidAuthToken() {
    return store.mestamasterApi.hasValidAuthToken();
  },
  connectCableConnection() {
    getEnv2(store).connectCableConnection();
  },
  disconnectCableConnection() {
    getEnv2(store).disconnectCableConnection();
  }
})).actions((store) => ({
  logoutDevice: flow18(function* () {
    if (!store.authenticatedDeviceId)
      return;
    const response = yield store.mestamasterApi.destroyDevice(store.authenticatedDeviceId);
    if (response.kind === "ok") {
      store.setAuthenticatedDeviceId(null);
    }
  })
})).actions((store) => ({
  restAuthStore: flow18(function* () {
    yield store.logoutDevice();
    store.setAuthenticatedUser(null);
  }),
  async setApiAuthToken(authToken) {
    await store.mestamasterApi.setAuthToken(authToken);
  }
})).actions((store) => ({
  logout: flow18(function* () {
    yield store.restAuthStore();
    store.setApiAuthToken("");
    store.disconnectCableConnection();
    const rootStore = store.root;
    if (rootStore) {
      rootStore.reset();
    }
  })
})).actions((store) => ({
  registerDevice(platform, expoToken, deviceToken) {
    store.mestamasterApi.createDevice(platform, expoToken, deviceToken).then((response) => {
      if (response.kind === "ok") {
        store.setAuthenticatedDeviceId(response.data.id);
      }
    });
  },
  fetchCurrentUser: flow18(function* () {
    const response = yield* toGenerator18(store.mestamasterApi.users.fetchCurrentUser());
    if (response.kind === "ok") {
      store.setAuthenticatedUser(response.data);
    } else {
      if (response.kind === "unauthorized") {
        yield store.logout();
      }
      console.error(`Error fetching current User: ${JSON.stringify(response)}`, []);
    }
    return response;
  })
})).actions((store) => ({
  login: flow18(function* (email, password) {
    const response = yield* toGenerator18(store.mestamasterApi.authentication.login({ email, password }));
    if (response.kind === "ok") {
      const { accessToken } = response.data;
      yield store.setApiAuthToken(accessToken);
      const user = yield store.fetchCurrentUser();
      store.connectCableConnection();
      return response;
    } else {
      console.error(`Error logging in: ${JSON.stringify(response)}`, []);
      return response;
    }
  }),
  register: flow18(function* (email, password, firstName, lastName, gdprConsent) {
    const response = yield* toGenerator18(store.mestamasterApi.authentication.register({ email, password, firstName, lastName, gdprConsent }));
    if (response.kind === "ok") {
      if (!response.meta) {
        throw new Error("No meta in response");
      }
      const { accessToken } = response.meta;
      yield store.setApiAuthToken(accessToken);
      store.setAuthenticatedUser(response.data);
      return response;
    } else {
      console.error(`Error registering: ${JSON.stringify(response)}`, []);
      return response;
    }
  }),
  requestPasswordlessSMSToken: flow18(function* (phoneNumber) {
    store.restAuthStore();
    const response = yield* toGenerator18(store.mestamasterApi.authentication.requestPasswordlessSMSToken(phoneNumber));
    if (response.kind === "ok") {
      return response;
    } else {
      console.error(`Error requesting passwordless token: ${JSON.stringify(response)}`, []);
      return response;
    }
  }),
  requestPasswordlessEmailToken: flow18(function* (email) {
    store.restAuthStore();
    const response = yield* toGenerator18(store.mestamasterApi.authentication.requestPasswordlessEmailToken(email));
    if (response.kind === "ok") {
      return response;
    } else {
      console.error(`Error requesting passwordless token: ${JSON.stringify(response)}`, []);
      return response;
    }
  }),
  loginWithPasswordless: flow18(function* (challenge, token) {
    const response = yield* toGenerator18(store.mestamasterApi.authentication.exchangePasswordlessToken(challenge, token));
    if (response.kind === "ok") {
      const { token: token2 } = response.data;
      yield store.setApiAuthToken(token2);
      return yield store.fetchCurrentUser();
    } else {
      console.error(`Error logging in with passwordless token: ${JSON.stringify(response)}`, []);
    }
    return response;
  }),
  reloadCurrentUser: flow18(function* () {
    yield store.fetchCurrentUser();
    if (store.authenticatedUser) {
      yield store.authenticatedUser.refresh();
    }
  }),
  resetVerifier() {
    store.oauthCodeVerifier = generateCodeVerifier();
    return store.oauthCodeVerifier;
  },
  initiateSSO: flow18(function* (provider) {
    const code = generateCodeVerifier();
    store.oauthCodeVerifier = code;
    const response = yield* toGenerator18(store.mestamasterApi.authentication.initiateSSO(provider));
    if (response.kind === "ok") {
      store.ssoProviders.set(response.data.provider, response.data);
      return response;
    } else {
      console.error(`Error initiating SSO: ${JSON.stringify(response)}`, []);
      return response;
    }
  }),
  handleSsoCallback: flow18(function* (provider, redirectUri, code) {
    if (!store.oauthCodeVerifier) {
      console.error("No code verifier found");
      return;
    }
    const response = yield* toGenerator18(store.mestamasterApi.authentication.oAuthTokenExchange(provider, redirectUri, code, store.oauthCodeVerifier));
    if (response.kind === "ok") {
      const { token } = response.data;
      store.setApiAuthToken(token);
      yield store.fetchCurrentUser();
      store.connectCableConnection();
    }
  })
}));

// src/data/stores/RootStore.ts
import { destroy as destroy8, flow as flow19, getEnv as getEnv3, types as types32 } from "mobx-state-tree";
var RootStoreModel = types32.model("RootStore").props({
  usersStore: types32.optional(UsersStoreModel, {}),
  companiesStore: types32.optional(CompaniesStoreModel, {}),
  projectsStore: types32.optional(ProjectsStoreModel, {}),
  authenticationStore: types32.optional(AuthenticationStoreModel, {})
}).extend(withApi).actions((self) => ({
  initApi(config) {
    getEnv3(self).environment.setup(config);
  },
  reset() {
    destroy8(self.usersStore);
    self.usersStore = UsersStoreModel.create({});
    destroy8(self.projectsStore);
    self.projectsStore = ProjectsStoreModel.create({});
    destroy8(self.companiesStore);
    self.authenticationStore = AuthenticationStoreModel.create({});
    destroy8(self.companiesStore);
    self.companiesStore = CompaniesStoreModel.create({});
  },
  softReset: flow19(function* () {
    self.usersStore = UsersStoreModel.create({});
    self.projectsStore = ProjectsStoreModel.create({});
    if (self.authenticationStore.isAuthenticated) {
      self.authenticationStore.reloadCurrentUser();
      self.projectsStore.fetchProjects();
    }
  }),
  setApiLocale(lang) {
    self.mestamasterApi.setApiLocale(lang);
  }
}));

// src/hooks/setupRootStore.ts
import { applySnapshot as applySnapshot18, onSnapshot } from "mobx-state-tree";

// src/services/storage/localStorageBackend/asyncStorage.ts
var asyncStorage_exports = {};
__export(asyncStorage_exports, {
  clear: () => clear,
  load: () => load,
  loadString: () => loadString,
  remove: () => remove,
  save: () => save,
  saveString: () => saveString
});
import AsyncStorage from "@react-native-async-storage/async-storage";
async function loadString(key) {
  try {
    return await AsyncStorage.getItem(key);
  } catch {
    return null;
  }
}
async function saveString(key, value) {
  try {
    await AsyncStorage.setItem(key, value);
    return true;
  } catch {
    return false;
  }
}
async function load(key) {
  try {
    const result = await AsyncStorage.getItem(key);
    if (result === null) {
      return null;
    }
    return JSON.parse(result);
  } catch {
    return null;
  }
}
async function save(key, value) {
  try {
    await AsyncStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch {
    return false;
  }
}
async function remove(key) {
  try {
    await AsyncStorage.removeItem(key);
  } catch {
  }
}
async function clear() {
  try {
    await AsyncStorage.clear();
  } catch {
  }
}

// src/hooks/setupRootStore.ts
var ROOT_STATE_STORAGE_KEY = "mestamaster-v1";
var _disposer;
async function setupRootStore(rootStore) {
  let restoredState;
  try {
    restoredState = await load(ROOT_STATE_STORAGE_KEY) || {};
    applySnapshot18(rootStore, restoredState);
  } catch (e) {
    await save(ROOT_STATE_STORAGE_KEY, {});
  }
  if (_disposer)
    _disposer();
  _disposer = onSnapshot(rootStore, (snapshot) => {
    save(ROOT_STATE_STORAGE_KEY, snapshot);
  });
  const unsubscribe = () => {
    _disposer();
  };
  return { rootStore, restoredState, unsubscribe };
}

// src/hooks/useStores.tsx
import { createContext, useContext, useEffect, useState } from "react";

// src/services/apis/mestamaster/mestamasterApi.ts
import {
  create
} from "apisauce";

// src/services/apis/apiProblem.ts
function getGeneralApiProblem(response) {
  switch (response.problem) {
    case "CONNECTION_ERROR":
      return { kind: "cannot-connect", temporary: true };
    case "NETWORK_ERROR":
      return { kind: "cannot-connect", temporary: true };
    case "TIMEOUT_ERROR":
      return { kind: "timeout", temporary: true };
    case "SERVER_ERROR":
      return { kind: "server", data: response.data };
    case "UNKNOWN_ERROR":
      return { kind: "unknown", temporary: true, data: response.data };
    case "CLIENT_ERROR":
      switch (response.status) {
        case 401:
          return { kind: "unauthorized", data: response.data };
        case 403:
          return { kind: "forbidden", data: response.data };
        case 404:
          return { kind: "not-found", data: response.data };
        default:
          return { kind: "rejected", data: response.data };
      }
    case "CANCEL_ERROR":
      return { kind: "unknown", temporary: true };
  }
  return { kind: "unknown", temporary: true };
}

// src/services/storage/secureStorage.ts
var secureStorage;
secureStorage = asyncStorage_exports;
var SecureStorageHandler = class {
  constructor() {
    this.strategy = asyncStorage_exports;
    import("./secureStorage-ZEBI4JWW.mjs").then((secureStorage2) => {
      return secureStorage2.isSecureStorageEnabled();
    }).then((isEnabled) => {
      if (isEnabled)
        this.strategy = secureStorage;
    }).catch(() => {
      console.debug("Falling back to asyncStorage");
    });
  }
  get(target, prop, receiver) {
    return Reflect.get(this.strategy, prop, receiver);
  }
};
var proxySecureStorage = new Proxy(asyncStorage_exports, new SecureStorageHandler());
var secureStorage_default = proxySecureStorage;

// src/services/apis/mestamaster/cableConnection.ts
var CableConnection = class {
  constructor(url, token) {
    this.socket = null;
    this.callbacks = /* @__PURE__ */ new Map();
    this.latestPing = new Date();
    this.connectionReattempts = 0;
    this.REATTEMPT_INTERVAL = 5e3;
    this.MAX_REATTEMPTS = 2;
    this.connect(url, token);
  }
  connect(url, token) {
    this.socket = new WebSocket(url + "?token=" + token);
    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data && data.identifier && data.message) {
        this.handleData(data.identifier, data.message);
      }
      if (data && data.type === "ping") {
        this.latestPing = new Date();
      }
    };
    this.socket.onopen = (event) => {
      this.connectionReattempts = 0;
      console.debug("Socket opened", event);
      this.resubscribe();
    };
    this.socket.onclose = (event) => {
      if (event.code === 1e3) {
        console.debug("Socket cleanly closed, will not reattempt", event);
        this.callbacks.clear();
        return;
      } else {
        if (this.connectionReattempts > this.MAX_REATTEMPTS) {
          console.debug(
            "Socket disconnected, could not reconnect after " + this.connectionReattempts + " attempts. Will not reattempt",
            event
          );
          this.callbacks.clear();
          return;
        }
        console.debug(
          "Socket disconnected, will reattempt (current " + this.connectionReattempts + ")",
          event
        );
        setTimeout(() => {
          this.connect(url, token);
        }, this.REATTEMPT_INTERVAL);
        this.connectionReattempts += 1;
      }
    };
    this.socket.onerror = (event) => {
      console.debug("Socket got an error", event);
      if (this.connectionReattempts > this.MAX_REATTEMPTS) {
        return;
      } else {
        setTimeout(() => {
          this.connect(url, token);
        }, this.REATTEMPT_INTERVAL);
      }
      this.connectionReattempts += 1;
    };
  }
  disconnect() {
    var _a;
    (_a = this.socket) == null ? void 0 : _a.close(1e3, "Logout disconnect");
  }
  resubscribe() {
    Array.from(this.callbacks.entries() || []).forEach(([identifier, _callbacks]) => {
      console.debug("Reconnecting to " + identifier);
      if (this.socket) {
        this.socket.send(JSON.stringify({ command: "subscribe", identifier }));
      }
    });
  }
  subscribe(channel, parameters, callback, onSubscriptionConfirmed) {
    const existingSubscription = Array.from(this.callbacks.keys()).find(
      (key) => key === JSON.stringify({ channel, ...parameters })
    );
    if (existingSubscription) {
      console.info("Already subscribed to " + existingSubscription + " - updating callback");
      this.attachCallback(existingSubscription, callback);
      return;
    }
    if (this.socket && this.socket.readyState !== this.socket.OPEN) {
      this.socket.onopen = () => {
        this.subscribe(channel, parameters, callback);
        console.debug("Socket (delayed) subscription");
      };
      return;
    }
    const identifier = JSON.stringify({ channel, ...parameters });
    if (this.socket) {
      this.socket.send(JSON.stringify({ command: "subscribe", identifier }));
      this.attachCallback(identifier, callback);
      if (onSubscriptionConfirmed) {
        onSubscriptionConfirmed(this.socket);
      }
    }
  }
  attachCallback(identifier, callback) {
    this.callbacks.set(identifier, [callback]);
  }
  handleData(identifier, data) {
    var _a;
    (_a = this.callbacks.get(identifier)) == null ? void 0 : _a.forEach((callback) => callback(data));
  }
};

// src/services/apis/mestamaster/modules/usersApi.ts
var UsersApiModule = class {
  constructor(mestaMasterApi) {
    this.mestaMasterApi = mestaMasterApi;
  }
  async getUser(userId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/users/" + userId
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchCurrentUser() {
    const response = await this.mestaMasterApi.apisauce.get("api/v3/user");
    return this.mestaMasterApi.handleResponse(response);
  }
  async updateCurrentUser(user) {
    const response = await this.mestaMasterApi.apisauce.patch(
      "api/v3/user",
      user
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchProjectUsers(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/users",
      { items: 1e4 }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async requestEmailVerification() {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/user/request_email_verification"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async requestPhoneVerification() {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/user/request_phone_number_verification"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async verifyEmail(token) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/user/verify_email",
      { token }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async verifyPhone(token) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/user/verify_phone_number",
      { token }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchCurrentUserProjectPulses() {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/user/project_pulses"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/restModule.ts
var RestModule = class {
  constructor(endpoint, api) {
    this.endpoint = endpoint;
    this.api = api;
  }
  async get(id) {
    const response = await this.api.apisauce.get(`${this.endpoint}/${id}`);
    return this.api.handleResponse(response);
  }
  async list(items, page) {
    const response = await this.api.apisauce.get(
      this.endpoint,
      { items: items || 1e4, page: page || 1 }
    );
    return this.api.handleResponse(response);
  }
  async create(data) {
    const response = await this.api.apisauce.post(this.endpoint, data);
    return this.api.handleResponse(response);
  }
  async update(id, data) {
    const response = await this.api.apisauce.put(`${this.endpoint}/${id}`, data);
    return this.api.handleResponse(response);
  }
  async partialUpdate(id, data) {
    const response = await this.api.apisauce.patch(`${this.endpoint}/${id}`, data);
    return this.api.handleResponse(response);
  }
  async delete(id) {
    const response = await this.api.apisauce.delete(`${this.endpoint}/${id}`);
    return this.api.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/companiesApi.ts
var CompaniesApiModule = class extends RestModule {
  constructor(mestaMasterApi) {
    super("api/v3/companies", mestaMasterApi);
    this.mestaMasterApi = mestaMasterApi;
  }
  async getCompanies() {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/companies",
      { items: 1e3 }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async getCompany(id) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/companies/" + id
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async getCompanyMemberships(companyId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/companies/" + companyId + "/company_memberships",
      { items: 1e3 }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/projectsApi.ts
var ProjectsApiModule = class extends RestModule {
  constructor(mestaMasterApi) {
    super("api/v3/projects", mestaMasterApi);
    this.mestaMasterApi = mestaMasterApi;
  }
  async getProject(id) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + id
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async createProject(companyId, newProject) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/companies/" + companyId + "/projects",
      newProject
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async updateProject(projectId, updatedObject) {
    const response = await this.mestaMasterApi.apisauce.patch(
      "api/v3/projects/" + projectId,
      updatedObject
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async getProjectAnalytics(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/basic_analytics"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async sendProjectPulse(projectId, value, skipped) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/projects/" + projectId + "/project_pulses",
      {
        value,
        skipped: !!skipped
      }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchProjectSuccessMetrics(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/analytics/success_metrics"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchPulseAnalytics(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/analytics/pulse_metrics"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/authenticationApi.ts
var AuthenticationApiModule = class {
  constructor(mestaMasterApi) {
    this.mestaMasterApi = mestaMasterApi;
  }
  async login(data) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v2/login",
      data
    );
    if (response.ok) {
      const newToken = response.data.authToken;
      this.mestaMasterApi.apisauce.setHeaders({
        Authorization: "Token " + newToken
      });
    }
    return this.mestaMasterApi.handleResponse(response);
  }
  async register(data) {
    const response = await this.mestaMasterApi.apisauce.post("api/v3/user", data);
    return this.mestaMasterApi.handleResponse(response);
  }
  async requestPasswordlessSMSToken(phoneNumber) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/auth/passwordless/request/phone",
      { phoneNumber }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async requestPasswordlessEmailToken(email) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/auth/passwordless/request/email",
      { email }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async exchangePasswordlessToken(challenge, token) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/auth/passwordless/redeem",
      { challenge, token }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async initiateSSO(email) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/auth/sso/initiate",
      { email }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async oAuthTokenExchange(provider, redirectUri, code, oauthCodeVerifier) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/auth/sso/exchange/" + provider,
      { oauthCode: code, oauthCodeVerifier, redirectUri }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/projectMembershipsApi.ts
var ProjectMembershipsApiModule = class extends RestModule {
  constructor(mestaMasterApi) {
    super("api/v3/project_memberships", mestaMasterApi);
    this.mestaMasterApi = mestaMasterApi;
  }
  async fetchProjectMemberships(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/project_memberships"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchProjectMembershipRequests(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/project_membership_requests"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async approveProjectMembershipRequest(requestId, role) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_requests/" + requestId + "/approve"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async rejectProjectMembershipRequest(requestId) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_requests/" + requestId + "/reject"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async createProjectMembershipRequest(code) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_requests",
      { projectCode: code }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchProjectMembershipInvites(projectId) {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/projects/" + projectId + "/project_membership_invites"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async fetchCurrentUserProjectMembershipInvites() {
    const response = await this.mestaMasterApi.apisauce.get(
      "api/v3/user/project_membership_invites"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async acceptProjectMembershipInvite(inviteId) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_invites/" + inviteId + "/accept"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async rejectProjectMembershipInvite(inviteId) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_invites/" + inviteId + "/reject"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async resendProjectMembershipInvite(inviteId) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/project_membership_invites/" + inviteId + "/resend"
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async inviteToProject(params) {
    const response = await this.mestaMasterApi.apisauce.post(
      "api/v3/projects/" + params.projectId + "/project_membership_invites",
      { ...params }
    );
    return this.mestaMasterApi.handleResponse(response);
  }
  async updateProjectMembershipInvite(id, data) {
    const response = await this.mestaMasterApi.apisauce.put(
      "api/v3/project_membership_invites/" + id,
      data
    );
    return this.mestaMasterApi.handleResponse(response);
  }
};

// src/services/apis/mestamaster/modules/companyMembershipsApi.ts
var CompanyMemershipsApiModule = class extends RestModule {
  constructor(mestaMasterApi) {
    super("api/v3/company_memberships", mestaMasterApi);
    this.mestaMasterApi = mestaMasterApi;
  }
};

// src/services/apis/mestamaster/modules/teamMembershipsApi.ts
var TeamMembershipsApiModule = class extends RestModule {
  constructor(mestaMasterApi) {
    super("api/v3/team_memberships", mestaMasterApi);
    this.mestaMasterApi = mestaMasterApi;
  }
};

// src/services/apis/mestamaster/mestamasterApi.ts
var DEFAULT_API_CONFIG = {
  url: "https://api.mestamaster.fi",
  wsUrl: "wss://api.mestamaster.fi",
  timeout: 1e4,
  onErrorCallback: (error) => {
    console.error("Error in API", error);
  }
};
var MestamasterApi = class {
  constructor(config = DEFAULT_API_CONFIG) {
    this.handleResponse = (response) => {
      if (!response.ok || response.data === void 0) {
        let problem = getGeneralApiProblem(response);
        if (!problem) {
          problem = { kind: "unknown", temporary: true };
        }
        if (this.config.onErrorCallback) {
          this.config.onErrorCallback(problem);
        }
        return problem;
      }
      if (response.status === 204) {
        return { kind: "ok", data: {}, meta: {} };
      }
      try {
        const data = response.data.data;
        return { kind: "ok", data, meta: response.data.meta };
      } catch (e) {
        console.log("error handle response", e, response);
        return { kind: "bad-data" };
      }
    };
    this.users = new UsersApiModule(this);
    this.companies = new CompaniesApiModule(this);
    this.projects = new ProjectsApiModule(this);
    this.authentication = new AuthenticationApiModule(this);
    this.projectMemberships = new ProjectMembershipsApiModule(this);
    this.companyMemberships = new CompanyMemershipsApiModule(this);
    this.teamMemberships = new TeamMembershipsApiModule(this);
    this.config = config;
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json"
      }
    });
    this.apisauce.axiosInstance.interceptors.request.use(
      async (config2) => {
        let token = await secureStorage_default.loadString("MESTAMASTER_TOKEN");
        if (token) {
          config2.headers.Authorization = `Token ${token}`;
        }
        return config2;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    this.apisauce.addMonitor(this.tokenMonitor);
  }
  tokenMonitor(response) {
    const { ok, status } = response;
    if (!ok && status === 401) {
      secureStorage_default.remove("MESTAMASTER_TOKEN");
    }
  }
  setApiLocale(lang) {
    this.apisauce.setHeaders({
      "Accept-Language": lang
    });
  }
  async hasValidAuthToken() {
    const token = await secureStorage_default.loadString("MESTAMASTER_TOKEN");
    if (token) {
      return true;
    }
    return false;
  }
  async setAuthToken(token) {
    if (!token)
      return await this.resetAuthToken();
    this.apisauce.setHeader("Authorization", "Token " + token);
    return await secureStorage_default.saveString("MESTAMASTER_TOKEN", token);
  }
  async resetAuthToken() {
    this.apisauce.setHeader("Authorization", "");
    return await secureStorage_default.remove("MESTAMASTER_TOKEN");
  }
  async createProjectStandaloneTodo(projectId, newTodo) {
    const response = await this.apisauce.post(
      "api/v3/projects/" + projectId + "/todos",
      newTodo
    );
    return this.handleResponse(response);
  }
  async getProjectTodos(project_id, filters) {
    let params = { items: filters.items };
    if (filters.userId) {
      params["useId"] = filters.userId;
    }
    if (filters.before) {
      params["endsBefore"] = filters.before.toISOString();
    }
    if (filters.after) {
      params["startsAfter"] = filters.after.toISOString();
    }
    if (filters.complete != void 0) {
      params["complete"] = filters.complete;
    }
    if (filters.own != void 0) {
      params["own"] = filters.own;
    }
    if (filters.updatedAfter) {
      params["updatedAfter"] = filters.updatedAfter.toISOString();
    }
    if (filters.todoIds) {
      params["todoIds"] = filters.todoIds;
    }
    if (filters.page) {
      params["page"] = filters.page;
    }
    const response = await this.apisauce.get(
      "api/v3/projects/" + project_id + "/todos",
      params,
      {
        headers: {
          "MM-Pagination-Mode": "keyset"
        }
      }
    );
    return this.handleResponse(response);
  }
  async getTaktTrainTodos(taktTrainId) {
    const response = await this.apisauce.get(
      "api/v3/takt/takt_trains/" + taktTrainId + "/todos"
    );
    return this.handleResponse(response);
  }
  async getTodoDetails(todoId) {
    const response = await this.apisauce.get(
      "api/v3/todos/" + todoId
    );
    return this.handleResponse(response);
  }
  async getTodoEvents(todo_id) {
    var _a, _b, _c;
    const response = await this.apisauce.get(
      "api/v3/todos/" + todo_id + "/todo_events"
    );
    const result = this.handleResponse(response);
    if ("data" in result) {
      return {
        ...result,
        data: result.data,
        meta: {
          pagination: {
            page: ((_a = result.meta) == null ? void 0 : _a.page) || 0,
            items: ((_b = result.meta) == null ? void 0 : _b.items) || 0,
            total: ((_c = result.meta) == null ? void 0 : _c.total) || 0
          }
        }
      };
    }
    return result;
  }
  async getTodoInterruptions(todo_id) {
    const response = await this.apisauce.get(
      "api/v3/todos/" + todo_id + "/interruptions"
    );
    return this.handleResponse(response);
  }
  async getTodoComments(todo_id) {
    const response = await this.apisauce.get(
      "api/v3/todos/" + todo_id + "/comments"
    );
    return this.handleResponse(response);
  }
  async getProjectTeams(project_id, page, items) {
    let params = { page, items };
    const response = await this.apisauce.get(
      "api/v3/projects/" + project_id + "/teams",
      params
    );
    return this.handleResponse(response);
  }
  async getTeamsAnalytics(project_id) {
    const response = await this.apisauce.get(
      "api/v3/projects/" + project_id + "/analytics/teams"
    );
    return this.handleResponse(response);
  }
  async createTeam(projectId, data) {
    const response = await this.apisauce.post(
      "api/v3/projects/" + projectId + "/teams",
      data
    );
    return this.handleResponse(response);
  }
  async updateTeam(teamId, data) {
    const response = await this.apisauce.put(
      "api/v3/teams/" + teamId,
      data
    );
    return this.handleResponse(response);
  }
  async deleteTeam(teamId) {
    const response = await this.apisauce.delete(
      "api/v3/teams/" + teamId
    );
    return this.handleResponse(response);
  }
  async getProjectTakts(project_id, page, items) {
    let params = { page, items };
    const response = await this.apisauce.get(
      "api/v3/projects/" + project_id + "/takt/takts",
      params
    );
    return this.handleResponse(response);
  }
  async emitTodoEvent(todoId, event) {
    const response = await this.apisauce.patch(
      "api/v3/todos/" + todoId + "/" + event
    );
    return this.handleResponse(response);
  }
  async createTodoInterruption(todoId, comment, reason) {
    const response = await this.apisauce.post(
      "api/v3/todos/" + todoId + "/interruptions",
      { comment, reason }
    );
    return this.handleResponse(response);
  }
  async createTodoComment(todoId, comment, files) {
    const formData = new FormData();
    formData.append("content", comment);
    if (files) {
      files.forEach((file) => {
        formData.append("attachments[]", file);
      });
    }
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    const response = await this.apisauce.post(
      "api/v3/todos/" + todoId + "/comments",
      formData,
      { headers }
    );
    return this.handleResponse(response);
  }
  async fetchUserTeamMemberships(userId) {
    const response = await this.apisauce.get(
      "api/v3/users/" + userId + "/team_memberships"
    );
    return this.handleResponse(response);
  }
  async createDevice(devicePlatform, expoToken, deviceToken) {
    const response = await this.apisauce.post(
      "api/v3/user/devices",
      {
        devicePlatform,
        expoToken,
        deviceToken
      }
    );
    return this.handleResponse(response);
  }
  async destroyDevice(deviceId) {
    const response = await this.apisauce.delete(
      "api/v3/users/devices/" + deviceId
    );
    return this.handleResponse(response);
  }
  async checkDeletedTodos(todoIds) {
    const response = await this.apisauce.post(
      "api/v3/todos/check_deleted",
      { todoIds }
    );
    return this.handleResponse(response);
  }
  async getTaktTrains(projectId, page, items) {
    let params = { page, items };
    const response = await this.apisauce.get(
      "api/v3/projects/" + projectId + "/takt/takt_trains",
      params
    );
    return this.handleResponse(response);
  }
  async completeChecklistItem(id) {
    const response = await this.apisauce.patch(
      "api/v3/todos/checklist_items/" + id + "/complete"
    );
    return this.handleResponse(response);
  }
  async undoChecklistItem(id) {
    const response = await this.apisauce.patch(
      "api/v3/todos/checklist_items/" + id + "/undo"
    );
    return this.handleResponse(response);
  }
  async getProjectSpaces(project_id, page, items) {
    let params = { page, items };
    const response = await this.apisauce.get(
      "api/v3/projects/" + project_id + "/spaces",
      params
    );
    return this.handleResponse(response);
  }
  async createProjectSpace(projectId, newSpace) {
    const response = await this.apisauce.post(
      "api/v3/projects/" + projectId + "/spaces",
      newSpace
    );
    return this.handleResponse(response);
  }
  async updateSpace(spaceId, updatedSpace) {
    const response = await this.apisauce.put(
      "api/v3/spaces/" + spaceId,
      updatedSpace
    );
    return this.handleResponse(response);
  }
  async deleteSpace(spaceId) {
    const response = await this.apisauce.delete(
      "api/v3/spaces/" + spaceId
    );
    return this.handleResponse(response);
  }
  async getTaktTrainTemplates(projectId) {
    const response = await this.apisauce.get(
      "api/v3/projects/" + projectId + "/takt_train_templates"
    );
    return this.handleResponse(response);
  }
  async getTaktTrainsByTemplateId(taktTrainTemplateId) {
    const response = await this.apisauce.get(
      "api/v3/takt_train_templates/" + taktTrainTemplateId + "/takt_trains"
    );
    return this.handleResponse(response);
  }
  async createTaktTrainTemplate(projectId, newTaktTrainTemplate) {
    const response = await this.apisauce.post(
      "api/v3/projects/" + projectId + "/takt_train_templates",
      newTaktTrainTemplate
    );
    return this.handleResponse(response);
  }
  async updateTaktTrainTemplate(taktTrainTemplateId, updatedTaktTrainTemplate) {
    const response = await this.apisauce.put(
      "api/v3/takt_train_templates/" + taktTrainTemplateId,
      updatedTaktTrainTemplate
    );
    return this.handleResponse(response);
  }
  async deleteTaktTrainTemplate(taktTrainTemplateId) {
    const response = await this.apisauce.delete(
      "api/v3/takt_train_templates/" + taktTrainTemplateId
    );
    return this.handleResponse(response);
  }
  async cloneTaktTrainTemplate(taktTrainTemplateId, clonedTrainData) {
    const response = await this.apisauce.post(
      "api/v3/takt_train_templates/" + taktTrainTemplateId + "/fork",
      clonedTrainData
    );
    return this.handleResponse(response);
  }
  async getTaktWagonTemplates(taktTrainTemplateId) {
    const response = await this.apisauce.get(
      "api/v3/takt_train_templates/" + taktTrainTemplateId + "/takt_wagon_templates/"
    );
    return this.handleResponse(response);
  }
  async createTaktWagonTemplate(taktTrainTemplateId, newTaktWagonTemplate) {
    const response = await this.apisauce.post(
      "api/v3/takt_train_templates/" + taktTrainTemplateId + "/takt_wagon_templates",
      newTaktWagonTemplate
    );
    return this.handleResponse(response);
  }
  async updateTaktWagonTemplate(taktWagonTemplateId, updatedTaktWagonTemplate) {
    const response = await this.apisauce.put(
      "api/v3/takt_wagon_templates/" + taktWagonTemplateId,
      updatedTaktWagonTemplate
    );
    return this.handleResponse(response);
  }
  async deleteTaktWagonTemplate(taktWagonTemplateId) {
    const response = await this.apisauce.delete(
      "api/v3/takt_wagon_templates/" + taktWagonTemplateId
    );
    return this.handleResponse(response);
  }
  async getTaskTemplates(taktWagonTemplateId) {
    const response = await this.apisauce.get(
      "api/v3/takt_wagon_templates/" + taktWagonTemplateId + "/task_templates/"
    );
    return this.handleResponse(response);
  }
  async createTaskTemplate(taktWagonTemplateId, newTaskTemplate) {
    const response = await this.apisauce.post(
      "api/v3/takt_wagon_templates/" + taktWagonTemplateId + "/task_templates/",
      newTaskTemplate
    );
    return this.handleResponse(response);
  }
  async updateTaskTemplate(taskTemplateId, updatedTaskTemplate) {
    const response = await this.apisauce.put(
      "api/v3/task_templates/" + taskTemplateId,
      updatedTaskTemplate
    );
    return this.handleResponse(response);
  }
  async deleteTaskTemplate(taskTemplateId) {
    const response = await this.apisauce.delete(
      "api/v3/task_templates/" + taskTemplateId
    );
    return this.handleResponse(response);
  }
  async updateTaktTrain(taktTrainId, updatedTaktTrain) {
    const response = await this.apisauce.patch(
      "api/v3/takt/takt_trains/" + taktTrainId,
      updatedTaktTrain
    );
    return this.handleResponse(response);
  }
  async createTaktTrain(projectId, newTaktTrain) {
    const response = await this.apisauce.post(
      "api/v3/projects/" + projectId + "/takt/takt_trains",
      newTaktTrain
    );
    return this.handleResponse(response);
  }
  async deleteTaktTrain(taktTrainId) {
    const response = await this.apisauce.delete(
      "api/v3/takt/takt_trains/" + taktTrainId
    );
    return this.handleResponse(response);
  }
  async getCableSubscription() {
    const token = await secureStorage_default.loadString("MESTAMASTER_TOKEN") || "";
    if (token === "") {
      throw new Error("Cannot connect while unauthenticated.");
    }
    return new CableConnection(this.config.wsUrl + "/cable", token);
  }
};

// src/data/environment.ts
var Environment = class {
  constructor(envConfig) {
    this.mestamasterApi = new MestamasterApi(envConfig);
    this.cableConnection = null;
    this.connectCableConnection();
  }
  connectCableConnection() {
    var _a, _b, _c, _d;
    if (this.cableConnection) {
      console.debug("Cable connection already exists");
      if (((_b = (_a = this.cableConnection) == null ? void 0 : _a.socket) == null ? void 0 : _b.readyState) === WebSocket.OPEN || ((_d = (_c = this.cableConnection) == null ? void 0 : _c.socket) == null ? void 0 : _d.readyState) === WebSocket.CONNECTING) {
        console.debug("Cable connection already open or connecting");
        return;
      }
      console.debug("Cable connection closed, reconnecting");
      this.disconnectCableConnection();
    }
    this.mestamasterApi.getCableSubscription().then((cableConnection) => {
      var _a2;
      this.cableConnection = cableConnection;
      if (!((_a2 = this.cableConnection) == null ? void 0 : _a2.socket)) {
        console.error("Cable connection not available");
        return;
      }
      this.cableConnection.socket.onopen = () => {
        console.debug("Cable connection opened");
      };
    }).catch((error) => {
      console.debug("Could not connect to websocket", error);
    });
  }
  disconnectCableConnection() {
    var _a;
    console.debug("Disconnecting cable connection");
    (_a = this.cableConnection) == null ? void 0 : _a.disconnect();
    this.cableConnection = null;
  }
};

// src/hooks/useStores.tsx
import { jsx } from "react/jsx-runtime";
var RootStoreContext = createContext(void 0);
function DataPlumberProvider({ envConfig, value, children }) {
  const env = envConfig ? new Environment(envConfig) : null;
  const [currentRoot, setCurrentRoot] = useState(value || RootStoreModel.create({}, env));
  return /* @__PURE__ */ jsx(RootStoreContext.Provider, {
    value: currentRoot,
    children
  });
}
function useStores() {
  const context = useContext(RootStoreContext);
  if (context === void 0) {
    throw new Error("useRootStore must be used within RootStoreProvider");
  }
  return context;
}
var useInitialRootStore = (callback) => {
  const rootStore = useStores();
  const [rehydrated, setRehydrated] = useState(false);
  useEffect(() => {
    let _unsubscribe;
    (async () => {
      const { restoredState, unsubscribe } = await setupRootStore(rootStore);
      _unsubscribe = unsubscribe;
      setRehydrated(true);
      if (callback)
        callback();
    })();
    return () => {
      if (_unsubscribe)
        _unsubscribe();
    };
  }, []);
  return { rootStore, rehydrated };
};

// src/hooks/getRootStore.ts
import { getRoot as getRoot2 } from "mobx-state-tree";
var getRootStore = (self) => {
  return getRoot2(self);
};

// src/utils/misc.ts
var snakeToCamel = (str) => str.toLowerCase().replace(
  /([-_][a-z])/g,
  (group) => group.toUpperCase().replace("-", "").replace("_", "")
);

// src/utils/translationUtils.ts
var interruptionReasonTranslationKey = (interruption) => {
  return "dataPlumber:models.interruption.reasons." + snakeToCamel(interruption.reason);
};
var todoEventTranslationKey = (todoEvent) => {
  return "dataPlumber:models.todoEvent." + snakeToCamel(todoEvent.event);
};
var todoTranslationKey = (todo) => {
  return "dataPlumber:models.todos.statuses." + snakeToCamel(todo.state);
};

// src/forms/userInfoForm.ts
import { z } from "zod";

// ../../node_modules/react-hook-form/dist/index.esm.mjs
import React from "react";
var isCheckBoxInput = (element) => element.type === "checkbox";
var isDateObject = (value) => value instanceof Date;
var isNullOrUndefined = (value) => value == null;
var isObjectType = (value) => typeof value === "object";
var isObject = (value) => !isNullOrUndefined(value) && !Array.isArray(value) && isObjectType(value) && !isDateObject(value);
var getEventValue = (event) => isObject(event) && event.target ? isCheckBoxInput(event.target) ? event.target.checked : event.target.value : event;
var getNodeParentName = (name) => name.substring(0, name.search(/\.\d+(\.|$)/)) || name;
var isNameInFieldArray = (names, name) => names.has(getNodeParentName(name));
var isPlainObject = (tempObject) => {
  const prototypeCopy = tempObject.constructor && tempObject.constructor.prototype;
  return isObject(prototypeCopy) && prototypeCopy.hasOwnProperty("isPrototypeOf");
};
var isWeb = typeof window !== "undefined" && typeof window.HTMLElement !== "undefined" && typeof document !== "undefined";
function cloneObject(data) {
  let copy;
  const isArray = Array.isArray(data);
  if (data instanceof Date) {
    copy = new Date(data);
  } else if (data instanceof Set) {
    copy = new Set(data);
  } else if (!(isWeb && (data instanceof Blob || data instanceof FileList)) && (isArray || isObject(data))) {
    copy = isArray ? [] : {};
    if (!isArray && !isPlainObject(data)) {
      copy = data;
    } else {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          copy[key] = cloneObject(data[key]);
        }
      }
    }
  } else {
    return data;
  }
  return copy;
}
var compact = (value) => Array.isArray(value) ? value.filter(Boolean) : [];
var isUndefined = (val) => val === void 0;
var get = (object, path, defaultValue) => {
  if (!path || !isObject(object)) {
    return defaultValue;
  }
  const result = compact(path.split(/[,[\].]+?/)).reduce((result2, key) => isNullOrUndefined(result2) ? result2 : result2[key], object);
  return isUndefined(result) || result === object ? isUndefined(object[path]) ? defaultValue : object[path] : result;
};
var isBoolean = (value) => typeof value === "boolean";
var isKey = (value) => /^\w*$/.test(value);
var stringToPath = (input) => compact(input.replace(/["|']|\]/g, "").split(/\.|\[/));
var set = (object, path, value) => {
  let index = -1;
  const tempPath = isKey(path) ? [path] : stringToPath(path);
  const length = tempPath.length;
  const lastIndex = length - 1;
  while (++index < length) {
    const key = tempPath[index];
    let newValue = value;
    if (index !== lastIndex) {
      const objValue = object[key];
      newValue = isObject(objValue) || Array.isArray(objValue) ? objValue : !isNaN(+tempPath[index + 1]) ? [] : {};
    }
    if (key === "__proto__") {
      return;
    }
    object[key] = newValue;
    object = object[key];
  }
  return object;
};
var EVENTS = {
  BLUR: "blur",
  FOCUS_OUT: "focusout",
  CHANGE: "change"
};
var VALIDATION_MODE = {
  onBlur: "onBlur",
  onChange: "onChange",
  onSubmit: "onSubmit",
  onTouched: "onTouched",
  all: "all"
};
var INPUT_VALIDATION_RULES = {
  max: "max",
  min: "min",
  maxLength: "maxLength",
  minLength: "minLength",
  pattern: "pattern",
  required: "required",
  validate: "validate"
};
var HookFormContext = React.createContext(null);
var getProxyFormState = (formState, control, localProxyFormState, isRoot = true) => {
  const result = {
    defaultValues: control._defaultValues
  };
  for (const key in formState) {
    Object.defineProperty(result, key, {
      get: () => {
        const _key = key;
        if (control._proxyFormState[_key] !== VALIDATION_MODE.all) {
          control._proxyFormState[_key] = !isRoot || VALIDATION_MODE.all;
        }
        localProxyFormState && (localProxyFormState[_key] = true);
        return formState[_key];
      }
    });
  }
  return result;
};
var isEmptyObject = (value) => isObject(value) && !Object.keys(value).length;
var shouldRenderFormState = (formStateData, _proxyFormState, updateFormState, isRoot) => {
  updateFormState(formStateData);
  const { name, ...formState } = formStateData;
  return isEmptyObject(formState) || Object.keys(formState).length >= Object.keys(_proxyFormState).length || Object.keys(formState).find((key) => _proxyFormState[key] === (!isRoot || VALIDATION_MODE.all));
};
var convertToArrayPayload = (value) => Array.isArray(value) ? value : [value];
function useSubscribe(props) {
  const _props = React.useRef(props);
  _props.current = props;
  React.useEffect(() => {
    const subscription = !props.disabled && _props.current.subject && _props.current.subject.subscribe({
      next: _props.current.next
    });
    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [props.disabled]);
}
var isString = (value) => typeof value === "string";
var generateWatchOutput = (names, _names, formValues, isGlobal, defaultValue) => {
  if (isString(names)) {
    isGlobal && _names.watch.add(names);
    return get(formValues, names, defaultValue);
  }
  if (Array.isArray(names)) {
    return names.map((fieldName) => (isGlobal && _names.watch.add(fieldName), get(formValues, fieldName)));
  }
  isGlobal && (_names.watchAll = true);
  return formValues;
};
var appendErrors = (name, validateAllFieldCriteria, errors, type, message) => validateAllFieldCriteria ? {
  ...errors[name],
  types: {
    ...errors[name] && errors[name].types ? errors[name].types : {},
    [type]: message || true
  }
} : {};
var getValidationModes = (mode) => ({
  isOnSubmit: !mode || mode === VALIDATION_MODE.onSubmit,
  isOnBlur: mode === VALIDATION_MODE.onBlur,
  isOnChange: mode === VALIDATION_MODE.onChange,
  isOnAll: mode === VALIDATION_MODE.all,
  isOnTouch: mode === VALIDATION_MODE.onTouched
});
var isWatched = (name, _names, isBlurEvent) => !isBlurEvent && (_names.watchAll || _names.watch.has(name) || [..._names.watch].some((watchName) => name.startsWith(watchName) && /^\.\w+/.test(name.slice(watchName.length))));
var iterateFieldsByAction = (fields, action, fieldsNames, abortEarly) => {
  for (const key of fieldsNames || Object.keys(fields)) {
    const field = get(fields, key);
    if (field) {
      const { _f, ...currentField } = field;
      if (_f) {
        if (_f.refs && _f.refs[0] && action(_f.refs[0], key) && !abortEarly) {
          return true;
        } else if (_f.ref && action(_f.ref, _f.name) && !abortEarly) {
          return true;
        } else {
          if (iterateFieldsByAction(currentField, action)) {
            break;
          }
        }
      } else if (isObject(currentField)) {
        if (iterateFieldsByAction(currentField, action)) {
          break;
        }
      }
    }
  }
  return;
};
var updateFieldArrayRootError = (errors, error, name) => {
  const fieldArrayErrors = convertToArrayPayload(get(errors, name));
  set(fieldArrayErrors, "root", error[name]);
  set(errors, name, fieldArrayErrors);
  return errors;
};
var isFileInput = (element) => element.type === "file";
var isFunction = (value) => typeof value === "function";
var isHTMLElement = (value) => {
  if (!isWeb) {
    return false;
  }
  const owner = value ? value.ownerDocument : 0;
  return value instanceof (owner && owner.defaultView ? owner.defaultView.HTMLElement : HTMLElement);
};
var isMessage = (value) => isString(value);
var isRadioInput = (element) => element.type === "radio";
var isRegex = (value) => value instanceof RegExp;
var defaultResult = {
  value: false,
  isValid: false
};
var validResult = { value: true, isValid: true };
var getCheckboxValue = (options) => {
  if (Array.isArray(options)) {
    if (options.length > 1) {
      const values = options.filter((option) => option && option.checked && !option.disabled).map((option) => option.value);
      return { value: values, isValid: !!values.length };
    }
    return options[0].checked && !options[0].disabled ? options[0].attributes && !isUndefined(options[0].attributes.value) ? isUndefined(options[0].value) || options[0].value === "" ? validResult : { value: options[0].value, isValid: true } : validResult : defaultResult;
  }
  return defaultResult;
};
var defaultReturn = {
  isValid: false,
  value: null
};
var getRadioValue = (options) => Array.isArray(options) ? options.reduce((previous, option) => option && option.checked && !option.disabled ? {
  isValid: true,
  value: option.value
} : previous, defaultReturn) : defaultReturn;
function getValidateError(result, ref, type = "validate") {
  if (isMessage(result) || Array.isArray(result) && result.every(isMessage) || isBoolean(result) && !result) {
    return {
      type,
      message: isMessage(result) ? result : "",
      ref
    };
  }
}
var getValueAndMessage = (validationData) => isObject(validationData) && !isRegex(validationData) ? validationData : {
  value: validationData,
  message: ""
};
var validateField = async (field, formValues, validateAllFieldCriteria, shouldUseNativeValidation, isFieldArray) => {
  const { ref, refs, required, maxLength, minLength, min, max, pattern, validate, name, valueAsNumber, mount, disabled } = field._f;
  const inputValue = get(formValues, name);
  if (!mount || disabled) {
    return {};
  }
  const inputRef = refs ? refs[0] : ref;
  const setCustomValidity = (message) => {
    if (shouldUseNativeValidation && inputRef.reportValidity) {
      inputRef.setCustomValidity(isBoolean(message) ? "" : message || "");
      inputRef.reportValidity();
    }
  };
  const error = {};
  const isRadio = isRadioInput(ref);
  const isCheckBox = isCheckBoxInput(ref);
  const isRadioOrCheckbox2 = isRadio || isCheckBox;
  const isEmpty = (valueAsNumber || isFileInput(ref)) && isUndefined(ref.value) && isUndefined(inputValue) || isHTMLElement(ref) && ref.value === "" || inputValue === "" || Array.isArray(inputValue) && !inputValue.length;
  const appendErrorsCurry = appendErrors.bind(null, name, validateAllFieldCriteria, error);
  const getMinMaxMessage = (exceedMax, maxLengthMessage, minLengthMessage, maxType = INPUT_VALIDATION_RULES.maxLength, minType = INPUT_VALIDATION_RULES.minLength) => {
    const message = exceedMax ? maxLengthMessage : minLengthMessage;
    error[name] = {
      type: exceedMax ? maxType : minType,
      message,
      ref,
      ...appendErrorsCurry(exceedMax ? maxType : minType, message)
    };
  };
  if (isFieldArray ? !Array.isArray(inputValue) || !inputValue.length : required && (!isRadioOrCheckbox2 && (isEmpty || isNullOrUndefined(inputValue)) || isBoolean(inputValue) && !inputValue || isCheckBox && !getCheckboxValue(refs).isValid || isRadio && !getRadioValue(refs).isValid)) {
    const { value, message } = isMessage(required) ? { value: !!required, message: required } : getValueAndMessage(required);
    if (value) {
      error[name] = {
        type: INPUT_VALIDATION_RULES.required,
        message,
        ref: inputRef,
        ...appendErrorsCurry(INPUT_VALIDATION_RULES.required, message)
      };
      if (!validateAllFieldCriteria) {
        setCustomValidity(message);
        return error;
      }
    }
  }
  if (!isEmpty && (!isNullOrUndefined(min) || !isNullOrUndefined(max))) {
    let exceedMax;
    let exceedMin;
    const maxOutput = getValueAndMessage(max);
    const minOutput = getValueAndMessage(min);
    if (!isNullOrUndefined(inputValue) && !isNaN(inputValue)) {
      const valueNumber = ref.valueAsNumber || (inputValue ? +inputValue : inputValue);
      if (!isNullOrUndefined(maxOutput.value)) {
        exceedMax = valueNumber > maxOutput.value;
      }
      if (!isNullOrUndefined(minOutput.value)) {
        exceedMin = valueNumber < minOutput.value;
      }
    } else {
      const valueDate = ref.valueAsDate || new Date(inputValue);
      const convertTimeToDate = (time) => new Date(new Date().toDateString() + " " + time);
      const isTime = ref.type == "time";
      const isWeek = ref.type == "week";
      if (isString(maxOutput.value) && inputValue) {
        exceedMax = isTime ? convertTimeToDate(inputValue) > convertTimeToDate(maxOutput.value) : isWeek ? inputValue > maxOutput.value : valueDate > new Date(maxOutput.value);
      }
      if (isString(minOutput.value) && inputValue) {
        exceedMin = isTime ? convertTimeToDate(inputValue) < convertTimeToDate(minOutput.value) : isWeek ? inputValue < minOutput.value : valueDate < new Date(minOutput.value);
      }
    }
    if (exceedMax || exceedMin) {
      getMinMaxMessage(!!exceedMax, maxOutput.message, minOutput.message, INPUT_VALIDATION_RULES.max, INPUT_VALIDATION_RULES.min);
      if (!validateAllFieldCriteria) {
        setCustomValidity(error[name].message);
        return error;
      }
    }
  }
  if ((maxLength || minLength) && !isEmpty && (isString(inputValue) || isFieldArray && Array.isArray(inputValue))) {
    const maxLengthOutput = getValueAndMessage(maxLength);
    const minLengthOutput = getValueAndMessage(minLength);
    const exceedMax = !isNullOrUndefined(maxLengthOutput.value) && inputValue.length > +maxLengthOutput.value;
    const exceedMin = !isNullOrUndefined(minLengthOutput.value) && inputValue.length < +minLengthOutput.value;
    if (exceedMax || exceedMin) {
      getMinMaxMessage(exceedMax, maxLengthOutput.message, minLengthOutput.message);
      if (!validateAllFieldCriteria) {
        setCustomValidity(error[name].message);
        return error;
      }
    }
  }
  if (pattern && !isEmpty && isString(inputValue)) {
    const { value: patternValue, message } = getValueAndMessage(pattern);
    if (isRegex(patternValue) && !inputValue.match(patternValue)) {
      error[name] = {
        type: INPUT_VALIDATION_RULES.pattern,
        message,
        ref,
        ...appendErrorsCurry(INPUT_VALIDATION_RULES.pattern, message)
      };
      if (!validateAllFieldCriteria) {
        setCustomValidity(message);
        return error;
      }
    }
  }
  if (validate) {
    if (isFunction(validate)) {
      const result = await validate(inputValue, formValues);
      const validateError = getValidateError(result, inputRef);
      if (validateError) {
        error[name] = {
          ...validateError,
          ...appendErrorsCurry(INPUT_VALIDATION_RULES.validate, validateError.message)
        };
        if (!validateAllFieldCriteria) {
          setCustomValidity(validateError.message);
          return error;
        }
      }
    } else if (isObject(validate)) {
      let validationResult = {};
      for (const key in validate) {
        if (!isEmptyObject(validationResult) && !validateAllFieldCriteria) {
          break;
        }
        const validateError = getValidateError(await validate[key](inputValue, formValues), inputRef, key);
        if (validateError) {
          validationResult = {
            ...validateError,
            ...appendErrorsCurry(key, validateError.message)
          };
          setCustomValidity(validateError.message);
          if (validateAllFieldCriteria) {
            error[name] = validationResult;
          }
        }
      }
      if (!isEmptyObject(validationResult)) {
        error[name] = {
          ref: inputRef,
          ...validationResult
        };
        if (!validateAllFieldCriteria) {
          return error;
        }
      }
    }
  }
  setCustomValidity(true);
  return error;
};
function baseGet(object, updatePath) {
  const length = updatePath.slice(0, -1).length;
  let index = 0;
  while (index < length) {
    object = isUndefined(object) ? index++ : object[updatePath[index++]];
  }
  return object;
}
function isEmptyArray(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && !isUndefined(obj[key])) {
      return false;
    }
  }
  return true;
}
function unset(object, path) {
  const paths = Array.isArray(path) ? path : isKey(path) ? [path] : stringToPath(path);
  const childObject = paths.length === 1 ? object : baseGet(object, paths);
  const index = paths.length - 1;
  const key = paths[index];
  if (childObject) {
    delete childObject[key];
  }
  if (index !== 0 && (isObject(childObject) && isEmptyObject(childObject) || Array.isArray(childObject) && isEmptyArray(childObject))) {
    unset(object, paths.slice(0, -1));
  }
  return object;
}
var createSubject = () => {
  let _observers = [];
  const next = (value) => {
    for (const observer of _observers) {
      observer.next && observer.next(value);
    }
  };
  const subscribe = (observer) => {
    _observers.push(observer);
    return {
      unsubscribe: () => {
        _observers = _observers.filter((o) => o !== observer);
      }
    };
  };
  const unsubscribe = () => {
    _observers = [];
  };
  return {
    get observers() {
      return _observers;
    },
    next,
    subscribe,
    unsubscribe
  };
};
var isPrimitive = (value) => isNullOrUndefined(value) || !isObjectType(value);
function deepEqual(object1, object2) {
  if (isPrimitive(object1) || isPrimitive(object2)) {
    return object1 === object2;
  }
  if (isDateObject(object1) && isDateObject(object2)) {
    return object1.getTime() === object2.getTime();
  }
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    if (!keys2.includes(key)) {
      return false;
    }
    if (key !== "ref") {
      const val2 = object2[key];
      if (isDateObject(val1) && isDateObject(val2) || isObject(val1) && isObject(val2) || Array.isArray(val1) && Array.isArray(val2) ? !deepEqual(val1, val2) : val1 !== val2) {
        return false;
      }
    }
  }
  return true;
}
var isMultipleSelect = (element) => element.type === `select-multiple`;
var isRadioOrCheckbox = (ref) => isRadioInput(ref) || isCheckBoxInput(ref);
var live = (ref) => isHTMLElement(ref) && ref.isConnected;
var objectHasFunction = (data) => {
  for (const key in data) {
    if (isFunction(data[key])) {
      return true;
    }
  }
  return false;
};
function markFieldsDirty(data, fields = {}) {
  const isParentNodeArray = Array.isArray(data);
  if (isObject(data) || isParentNodeArray) {
    for (const key in data) {
      if (Array.isArray(data[key]) || isObject(data[key]) && !objectHasFunction(data[key])) {
        fields[key] = Array.isArray(data[key]) ? [] : {};
        markFieldsDirty(data[key], fields[key]);
      } else if (!isNullOrUndefined(data[key])) {
        fields[key] = true;
      }
    }
  }
  return fields;
}
function getDirtyFieldsFromDefaultValues(data, formValues, dirtyFieldsFromValues) {
  const isParentNodeArray = Array.isArray(data);
  if (isObject(data) || isParentNodeArray) {
    for (const key in data) {
      if (Array.isArray(data[key]) || isObject(data[key]) && !objectHasFunction(data[key])) {
        if (isUndefined(formValues) || isPrimitive(dirtyFieldsFromValues[key])) {
          dirtyFieldsFromValues[key] = Array.isArray(data[key]) ? markFieldsDirty(data[key], []) : { ...markFieldsDirty(data[key]) };
        } else {
          getDirtyFieldsFromDefaultValues(data[key], isNullOrUndefined(formValues) ? {} : formValues[key], dirtyFieldsFromValues[key]);
        }
      } else {
        dirtyFieldsFromValues[key] = !deepEqual(data[key], formValues[key]);
      }
    }
  }
  return dirtyFieldsFromValues;
}
var getDirtyFields = (defaultValues, formValues) => getDirtyFieldsFromDefaultValues(defaultValues, formValues, markFieldsDirty(formValues));
var getFieldValueAs = (value, { valueAsNumber, valueAsDate, setValueAs }) => isUndefined(value) ? value : valueAsNumber ? value === "" ? NaN : value ? +value : value : valueAsDate && isString(value) ? new Date(value) : setValueAs ? setValueAs(value) : value;
function getFieldValue(_f) {
  const ref = _f.ref;
  if (_f.refs ? _f.refs.every((ref2) => ref2.disabled) : ref.disabled) {
    return;
  }
  if (isFileInput(ref)) {
    return ref.files;
  }
  if (isRadioInput(ref)) {
    return getRadioValue(_f.refs).value;
  }
  if (isMultipleSelect(ref)) {
    return [...ref.selectedOptions].map(({ value }) => value);
  }
  if (isCheckBoxInput(ref)) {
    return getCheckboxValue(_f.refs).value;
  }
  return getFieldValueAs(isUndefined(ref.value) ? _f.ref.value : ref.value, _f);
}
var getResolverOptions = (fieldsNames, _fields, criteriaMode, shouldUseNativeValidation) => {
  const fields = {};
  for (const name of fieldsNames) {
    const field = get(_fields, name);
    field && set(fields, name, field._f);
  }
  return {
    criteriaMode,
    names: [...fieldsNames],
    fields,
    shouldUseNativeValidation
  };
};
var getRuleValue = (rule) => isUndefined(rule) ? rule : isRegex(rule) ? rule.source : isObject(rule) ? isRegex(rule.value) ? rule.value.source : rule.value : rule;
var ASYNC_FUNCTION = "AsyncFunction";
var hasPromiseValidation = (fieldReference) => (!fieldReference || !fieldReference.validate) && !!(isFunction(fieldReference.validate) && fieldReference.validate.constructor.name === ASYNC_FUNCTION || isObject(fieldReference.validate) && Object.values(fieldReference.validate).find((validateFunction) => validateFunction.constructor.name === ASYNC_FUNCTION));
var hasValidation = (options) => options.mount && (options.required || options.min || options.max || options.maxLength || options.minLength || options.pattern || options.validate);
function schemaErrorLookup(errors, _fields, name) {
  const error = get(errors, name);
  if (error || isKey(name)) {
    return {
      error,
      name
    };
  }
  const names = name.split(".");
  while (names.length) {
    const fieldName = names.join(".");
    const field = get(_fields, fieldName);
    const foundError = get(errors, fieldName);
    if (field && !Array.isArray(field) && name !== fieldName) {
      return { name };
    }
    if (foundError && foundError.type) {
      return {
        name: fieldName,
        error: foundError
      };
    }
    names.pop();
  }
  return {
    name
  };
}
var skipValidation = (isBlurEvent, isTouched, isSubmitted, reValidateMode, mode) => {
  if (mode.isOnAll) {
    return false;
  } else if (!isSubmitted && mode.isOnTouch) {
    return !(isTouched || isBlurEvent);
  } else if (isSubmitted ? reValidateMode.isOnBlur : mode.isOnBlur) {
    return !isBlurEvent;
  } else if (isSubmitted ? reValidateMode.isOnChange : mode.isOnChange) {
    return isBlurEvent;
  }
  return true;
};
var unsetEmptyArray = (ref, name) => !compact(get(ref, name)).length && unset(ref, name);
var defaultOptions = {
  mode: VALIDATION_MODE.onSubmit,
  reValidateMode: VALIDATION_MODE.onChange,
  shouldFocusError: true
};
function createFormControl(props = {}) {
  let _options = {
    ...defaultOptions,
    ...props
  };
  let _formState = {
    submitCount: 0,
    isDirty: false,
    isLoading: isFunction(_options.defaultValues),
    isValidating: false,
    isSubmitted: false,
    isSubmitting: false,
    isSubmitSuccessful: false,
    isValid: false,
    touchedFields: {},
    dirtyFields: {},
    validatingFields: {},
    errors: _options.errors || {},
    disabled: _options.disabled || false
  };
  let _fields = {};
  let _defaultValues = isObject(_options.defaultValues) || isObject(_options.values) ? cloneObject(_options.defaultValues || _options.values) || {} : {};
  let _formValues = _options.shouldUnregister ? {} : cloneObject(_defaultValues);
  let _state = {
    action: false,
    mount: false,
    watch: false
  };
  let _names = {
    mount: /* @__PURE__ */ new Set(),
    unMount: /* @__PURE__ */ new Set(),
    array: /* @__PURE__ */ new Set(),
    watch: /* @__PURE__ */ new Set()
  };
  let delayErrorCallback;
  let timer = 0;
  const _proxyFormState = {
    isDirty: false,
    dirtyFields: false,
    validatingFields: false,
    touchedFields: false,
    isValidating: false,
    isValid: false,
    errors: false
  };
  const _subjects = {
    values: createSubject(),
    array: createSubject(),
    state: createSubject()
  };
  const validationModeBeforeSubmit = getValidationModes(_options.mode);
  const validationModeAfterSubmit = getValidationModes(_options.reValidateMode);
  const shouldDisplayAllAssociatedErrors = _options.criteriaMode === VALIDATION_MODE.all;
  const debounce = (callback) => (wait) => {
    clearTimeout(timer);
    timer = setTimeout(callback, wait);
  };
  const _updateValid = async (shouldUpdateValid) => {
    if (!props.disabled && (_proxyFormState.isValid || shouldUpdateValid)) {
      const isValid = _options.resolver ? isEmptyObject((await _executeSchema()).errors) : await executeBuiltInValidation(_fields, true);
      if (isValid !== _formState.isValid) {
        _subjects.state.next({
          isValid
        });
      }
    }
  };
  const _updateIsValidating = (names, isValidating) => {
    if (!props.disabled && (_proxyFormState.isValidating || _proxyFormState.validatingFields)) {
      (names || Array.from(_names.mount)).forEach((name) => {
        if (name) {
          isValidating ? set(_formState.validatingFields, name, isValidating) : unset(_formState.validatingFields, name);
        }
      });
      _subjects.state.next({
        validatingFields: _formState.validatingFields,
        isValidating: !isEmptyObject(_formState.validatingFields)
      });
    }
  };
  const _updateFieldArray = (name, values = [], method, args, shouldSetValues = true, shouldUpdateFieldsAndState = true) => {
    if (args && method && !props.disabled) {
      _state.action = true;
      if (shouldUpdateFieldsAndState && Array.isArray(get(_fields, name))) {
        const fieldValues = method(get(_fields, name), args.argA, args.argB);
        shouldSetValues && set(_fields, name, fieldValues);
      }
      if (shouldUpdateFieldsAndState && Array.isArray(get(_formState.errors, name))) {
        const errors = method(get(_formState.errors, name), args.argA, args.argB);
        shouldSetValues && set(_formState.errors, name, errors);
        unsetEmptyArray(_formState.errors, name);
      }
      if (_proxyFormState.touchedFields && shouldUpdateFieldsAndState && Array.isArray(get(_formState.touchedFields, name))) {
        const touchedFields = method(get(_formState.touchedFields, name), args.argA, args.argB);
        shouldSetValues && set(_formState.touchedFields, name, touchedFields);
      }
      if (_proxyFormState.dirtyFields) {
        _formState.dirtyFields = getDirtyFields(_defaultValues, _formValues);
      }
      _subjects.state.next({
        name,
        isDirty: _getDirty(name, values),
        dirtyFields: _formState.dirtyFields,
        errors: _formState.errors,
        isValid: _formState.isValid
      });
    } else {
      set(_formValues, name, values);
    }
  };
  const updateErrors = (name, error) => {
    set(_formState.errors, name, error);
    _subjects.state.next({
      errors: _formState.errors
    });
  };
  const _setErrors = (errors) => {
    _formState.errors = errors;
    _subjects.state.next({
      errors: _formState.errors,
      isValid: false
    });
  };
  const updateValidAndValue = (name, shouldSkipSetValueAs, value, ref) => {
    const field = get(_fields, name);
    if (field) {
      const defaultValue = get(_formValues, name, isUndefined(value) ? get(_defaultValues, name) : value);
      isUndefined(defaultValue) || ref && ref.defaultChecked || shouldSkipSetValueAs ? set(_formValues, name, shouldSkipSetValueAs ? defaultValue : getFieldValue(field._f)) : setFieldValue(name, defaultValue);
      _state.mount && _updateValid();
    }
  };
  const updateTouchAndDirty = (name, fieldValue, isBlurEvent, shouldDirty, shouldRender) => {
    let shouldUpdateField = false;
    let isPreviousDirty = false;
    const output = {
      name
    };
    if (!props.disabled) {
      const disabledField = !!(get(_fields, name) && get(_fields, name)._f && get(_fields, name)._f.disabled);
      if (!isBlurEvent || shouldDirty) {
        if (_proxyFormState.isDirty) {
          isPreviousDirty = _formState.isDirty;
          _formState.isDirty = output.isDirty = _getDirty();
          shouldUpdateField = isPreviousDirty !== output.isDirty;
        }
        const isCurrentFieldPristine = disabledField || deepEqual(get(_defaultValues, name), fieldValue);
        isPreviousDirty = !!(!disabledField && get(_formState.dirtyFields, name));
        isCurrentFieldPristine || disabledField ? unset(_formState.dirtyFields, name) : set(_formState.dirtyFields, name, true);
        output.dirtyFields = _formState.dirtyFields;
        shouldUpdateField = shouldUpdateField || _proxyFormState.dirtyFields && isPreviousDirty !== !isCurrentFieldPristine;
      }
      if (isBlurEvent) {
        const isPreviousFieldTouched = get(_formState.touchedFields, name);
        if (!isPreviousFieldTouched) {
          set(_formState.touchedFields, name, isBlurEvent);
          output.touchedFields = _formState.touchedFields;
          shouldUpdateField = shouldUpdateField || _proxyFormState.touchedFields && isPreviousFieldTouched !== isBlurEvent;
        }
      }
      shouldUpdateField && shouldRender && _subjects.state.next(output);
    }
    return shouldUpdateField ? output : {};
  };
  const shouldRenderByError = (name, isValid, error, fieldState) => {
    const previousFieldError = get(_formState.errors, name);
    const shouldUpdateValid = _proxyFormState.isValid && isBoolean(isValid) && _formState.isValid !== isValid;
    if (props.delayError && error) {
      delayErrorCallback = debounce(() => updateErrors(name, error));
      delayErrorCallback(props.delayError);
    } else {
      clearTimeout(timer);
      delayErrorCallback = null;
      error ? set(_formState.errors, name, error) : unset(_formState.errors, name);
    }
    if ((error ? !deepEqual(previousFieldError, error) : previousFieldError) || !isEmptyObject(fieldState) || shouldUpdateValid) {
      const updatedFormState = {
        ...fieldState,
        ...shouldUpdateValid && isBoolean(isValid) ? { isValid } : {},
        errors: _formState.errors,
        name
      };
      _formState = {
        ..._formState,
        ...updatedFormState
      };
      _subjects.state.next(updatedFormState);
    }
  };
  const _executeSchema = async (name) => {
    _updateIsValidating(name, true);
    const result = await _options.resolver(_formValues, _options.context, getResolverOptions(name || _names.mount, _fields, _options.criteriaMode, _options.shouldUseNativeValidation));
    _updateIsValidating(name);
    return result;
  };
  const executeSchemaAndUpdateState = async (names) => {
    const { errors } = await _executeSchema(names);
    if (names) {
      for (const name of names) {
        const error = get(errors, name);
        error ? set(_formState.errors, name, error) : unset(_formState.errors, name);
      }
    } else {
      _formState.errors = errors;
    }
    return errors;
  };
  const executeBuiltInValidation = async (fields, shouldOnlyCheckValid, context = {
    valid: true
  }) => {
    for (const name in fields) {
      const field = fields[name];
      if (field) {
        const { _f, ...fieldValue } = field;
        if (_f) {
          const isFieldArrayRoot = _names.array.has(_f.name);
          const isPromiseFunction = field._f && hasPromiseValidation(field._f);
          if (isPromiseFunction && _proxyFormState.validatingFields) {
            _updateIsValidating([name], true);
          }
          const fieldError = await validateField(field, _formValues, shouldDisplayAllAssociatedErrors, _options.shouldUseNativeValidation && !shouldOnlyCheckValid, isFieldArrayRoot);
          if (isPromiseFunction && _proxyFormState.validatingFields) {
            _updateIsValidating([name]);
          }
          if (fieldError[_f.name]) {
            context.valid = false;
            if (shouldOnlyCheckValid) {
              break;
            }
          }
          !shouldOnlyCheckValid && (get(fieldError, _f.name) ? isFieldArrayRoot ? updateFieldArrayRootError(_formState.errors, fieldError, _f.name) : set(_formState.errors, _f.name, fieldError[_f.name]) : unset(_formState.errors, _f.name));
        }
        !isEmptyObject(fieldValue) && await executeBuiltInValidation(fieldValue, shouldOnlyCheckValid, context);
      }
    }
    return context.valid;
  };
  const _removeUnmounted = () => {
    for (const name of _names.unMount) {
      const field = get(_fields, name);
      field && (field._f.refs ? field._f.refs.every((ref) => !live(ref)) : !live(field._f.ref)) && unregister(name);
    }
    _names.unMount = /* @__PURE__ */ new Set();
  };
  const _getDirty = (name, data) => !props.disabled && (name && data && set(_formValues, name, data), !deepEqual(getValues(), _defaultValues));
  const _getWatch = (names, defaultValue, isGlobal) => generateWatchOutput(names, _names, {
    ..._state.mount ? _formValues : isUndefined(defaultValue) ? _defaultValues : isString(names) ? { [names]: defaultValue } : defaultValue
  }, isGlobal, defaultValue);
  const _getFieldArray = (name) => compact(get(_state.mount ? _formValues : _defaultValues, name, props.shouldUnregister ? get(_defaultValues, name, []) : []));
  const setFieldValue = (name, value, options = {}) => {
    const field = get(_fields, name);
    let fieldValue = value;
    if (field) {
      const fieldReference = field._f;
      if (fieldReference) {
        !fieldReference.disabled && set(_formValues, name, getFieldValueAs(value, fieldReference));
        fieldValue = isHTMLElement(fieldReference.ref) && isNullOrUndefined(value) ? "" : value;
        if (isMultipleSelect(fieldReference.ref)) {
          [...fieldReference.ref.options].forEach((optionRef) => optionRef.selected = fieldValue.includes(optionRef.value));
        } else if (fieldReference.refs) {
          if (isCheckBoxInput(fieldReference.ref)) {
            fieldReference.refs.length > 1 ? fieldReference.refs.forEach((checkboxRef) => (!checkboxRef.defaultChecked || !checkboxRef.disabled) && (checkboxRef.checked = Array.isArray(fieldValue) ? !!fieldValue.find((data) => data === checkboxRef.value) : fieldValue === checkboxRef.value)) : fieldReference.refs[0] && (fieldReference.refs[0].checked = !!fieldValue);
          } else {
            fieldReference.refs.forEach((radioRef) => radioRef.checked = radioRef.value === fieldValue);
          }
        } else if (isFileInput(fieldReference.ref)) {
          fieldReference.ref.value = "";
        } else {
          fieldReference.ref.value = fieldValue;
          if (!fieldReference.ref.type) {
            _subjects.values.next({
              name,
              values: { ..._formValues }
            });
          }
        }
      }
    }
    (options.shouldDirty || options.shouldTouch) && updateTouchAndDirty(name, fieldValue, options.shouldTouch, options.shouldDirty, true);
    options.shouldValidate && trigger(name);
  };
  const setValues = (name, value, options) => {
    for (const fieldKey in value) {
      const fieldValue = value[fieldKey];
      const fieldName = `${name}.${fieldKey}`;
      const field = get(_fields, fieldName);
      (_names.array.has(name) || isObject(fieldValue) || field && !field._f) && !isDateObject(fieldValue) ? setValues(fieldName, fieldValue, options) : setFieldValue(fieldName, fieldValue, options);
    }
  };
  const setValue = (name, value, options = {}) => {
    const field = get(_fields, name);
    const isFieldArray = _names.array.has(name);
    const cloneValue = cloneObject(value);
    set(_formValues, name, cloneValue);
    if (isFieldArray) {
      _subjects.array.next({
        name,
        values: { ..._formValues }
      });
      if ((_proxyFormState.isDirty || _proxyFormState.dirtyFields) && options.shouldDirty) {
        _subjects.state.next({
          name,
          dirtyFields: getDirtyFields(_defaultValues, _formValues),
          isDirty: _getDirty(name, cloneValue)
        });
      }
    } else {
      field && !field._f && !isNullOrUndefined(cloneValue) ? setValues(name, cloneValue, options) : setFieldValue(name, cloneValue, options);
    }
    isWatched(name, _names) && _subjects.state.next({ ..._formState });
    _subjects.values.next({
      name: _state.mount ? name : void 0,
      values: { ..._formValues }
    });
  };
  const onChange = async (event) => {
    _state.mount = true;
    const target = event.target;
    let name = target.name;
    let isFieldValueUpdated = true;
    const field = get(_fields, name);
    const getCurrentFieldValue = () => target.type ? getFieldValue(field._f) : getEventValue(event);
    const _updateIsFieldValueUpdated = (fieldValue) => {
      isFieldValueUpdated = Number.isNaN(fieldValue) || isDateObject(fieldValue) && isNaN(fieldValue.getTime()) || deepEqual(fieldValue, get(_formValues, name, fieldValue));
    };
    if (field) {
      let error;
      let isValid;
      const fieldValue = getCurrentFieldValue();
      const isBlurEvent = event.type === EVENTS.BLUR || event.type === EVENTS.FOCUS_OUT;
      const shouldSkipValidation = !hasValidation(field._f) && !_options.resolver && !get(_formState.errors, name) && !field._f.deps || skipValidation(isBlurEvent, get(_formState.touchedFields, name), _formState.isSubmitted, validationModeAfterSubmit, validationModeBeforeSubmit);
      const watched = isWatched(name, _names, isBlurEvent);
      set(_formValues, name, fieldValue);
      if (isBlurEvent) {
        field._f.onBlur && field._f.onBlur(event);
        delayErrorCallback && delayErrorCallback(0);
      } else if (field._f.onChange) {
        field._f.onChange(event);
      }
      const fieldState = updateTouchAndDirty(name, fieldValue, isBlurEvent, false);
      const shouldRender = !isEmptyObject(fieldState) || watched;
      !isBlurEvent && _subjects.values.next({
        name,
        type: event.type,
        values: { ..._formValues }
      });
      if (shouldSkipValidation) {
        if (_proxyFormState.isValid) {
          if (props.mode === "onBlur") {
            if (isBlurEvent) {
              _updateValid();
            }
          } else {
            _updateValid();
          }
        }
        return shouldRender && _subjects.state.next({ name, ...watched ? {} : fieldState });
      }
      !isBlurEvent && watched && _subjects.state.next({ ..._formState });
      if (_options.resolver) {
        const { errors } = await _executeSchema([name]);
        _updateIsFieldValueUpdated(fieldValue);
        if (isFieldValueUpdated) {
          const previousErrorLookupResult = schemaErrorLookup(_formState.errors, _fields, name);
          const errorLookupResult = schemaErrorLookup(errors, _fields, previousErrorLookupResult.name || name);
          error = errorLookupResult.error;
          name = errorLookupResult.name;
          isValid = isEmptyObject(errors);
        }
      } else {
        _updateIsValidating([name], true);
        error = (await validateField(field, _formValues, shouldDisplayAllAssociatedErrors, _options.shouldUseNativeValidation))[name];
        _updateIsValidating([name]);
        _updateIsFieldValueUpdated(fieldValue);
        if (isFieldValueUpdated) {
          if (error) {
            isValid = false;
          } else if (_proxyFormState.isValid) {
            isValid = await executeBuiltInValidation(_fields, true);
          }
        }
      }
      if (isFieldValueUpdated) {
        field._f.deps && trigger(field._f.deps);
        shouldRenderByError(name, isValid, error, fieldState);
      }
    }
  };
  const _focusInput = (ref, key) => {
    if (get(_formState.errors, key) && ref.focus) {
      ref.focus();
      return 1;
    }
    return;
  };
  const trigger = async (name, options = {}) => {
    let isValid;
    let validationResult;
    const fieldNames = convertToArrayPayload(name);
    if (_options.resolver) {
      const errors = await executeSchemaAndUpdateState(isUndefined(name) ? name : fieldNames);
      isValid = isEmptyObject(errors);
      validationResult = name ? !fieldNames.some((name2) => get(errors, name2)) : isValid;
    } else if (name) {
      validationResult = (await Promise.all(fieldNames.map(async (fieldName) => {
        const field = get(_fields, fieldName);
        return await executeBuiltInValidation(field && field._f ? { [fieldName]: field } : field);
      }))).every(Boolean);
      !(!validationResult && !_formState.isValid) && _updateValid();
    } else {
      validationResult = isValid = await executeBuiltInValidation(_fields);
    }
    _subjects.state.next({
      ...!isString(name) || _proxyFormState.isValid && isValid !== _formState.isValid ? {} : { name },
      ..._options.resolver || !name ? { isValid } : {},
      errors: _formState.errors
    });
    options.shouldFocus && !validationResult && iterateFieldsByAction(_fields, _focusInput, name ? fieldNames : _names.mount);
    return validationResult;
  };
  const getValues = (fieldNames) => {
    const values = {
      ..._state.mount ? _formValues : _defaultValues
    };
    return isUndefined(fieldNames) ? values : isString(fieldNames) ? get(values, fieldNames) : fieldNames.map((name) => get(values, name));
  };
  const getFieldState = (name, formState) => ({
    invalid: !!get((formState || _formState).errors, name),
    isDirty: !!get((formState || _formState).dirtyFields, name),
    error: get((formState || _formState).errors, name),
    isValidating: !!get(_formState.validatingFields, name),
    isTouched: !!get((formState || _formState).touchedFields, name)
  });
  const clearErrors = (name) => {
    name && convertToArrayPayload(name).forEach((inputName) => unset(_formState.errors, inputName));
    _subjects.state.next({
      errors: name ? _formState.errors : {}
    });
  };
  const setError = (name, error, options) => {
    const ref = (get(_fields, name, { _f: {} })._f || {}).ref;
    const currentError = get(_formState.errors, name) || {};
    const { ref: currentRef, message, type, ...restOfErrorTree } = currentError;
    set(_formState.errors, name, {
      ...restOfErrorTree,
      ...error,
      ref
    });
    _subjects.state.next({
      name,
      errors: _formState.errors,
      isValid: false
    });
    options && options.shouldFocus && ref && ref.focus && ref.focus();
  };
  const watch = (name, defaultValue) => isFunction(name) ? _subjects.values.subscribe({
    next: (payload) => name(_getWatch(void 0, defaultValue), payload)
  }) : _getWatch(name, defaultValue, true);
  const unregister = (name, options = {}) => {
    for (const fieldName of name ? convertToArrayPayload(name) : _names.mount) {
      _names.mount.delete(fieldName);
      _names.array.delete(fieldName);
      if (!options.keepValue) {
        unset(_fields, fieldName);
        unset(_formValues, fieldName);
      }
      !options.keepError && unset(_formState.errors, fieldName);
      !options.keepDirty && unset(_formState.dirtyFields, fieldName);
      !options.keepTouched && unset(_formState.touchedFields, fieldName);
      !options.keepIsValidating && unset(_formState.validatingFields, fieldName);
      !_options.shouldUnregister && !options.keepDefaultValue && unset(_defaultValues, fieldName);
    }
    _subjects.values.next({
      values: { ..._formValues }
    });
    _subjects.state.next({
      ..._formState,
      ...!options.keepDirty ? {} : { isDirty: _getDirty() }
    });
    !options.keepIsValid && _updateValid();
  };
  const _updateDisabledField = ({ disabled, name, field, fields, value }) => {
    if (isBoolean(disabled) && _state.mount || !!disabled) {
      const inputValue = disabled ? void 0 : isUndefined(value) ? getFieldValue(field ? field._f : get(fields, name)._f) : value;
      set(_formValues, name, inputValue);
      updateTouchAndDirty(name, inputValue, false, false, true);
    }
  };
  const register = (name, options = {}) => {
    let field = get(_fields, name);
    const disabledIsDefined = isBoolean(options.disabled) || isBoolean(props.disabled);
    set(_fields, name, {
      ...field || {},
      _f: {
        ...field && field._f ? field._f : { ref: { name } },
        name,
        mount: true,
        ...options
      }
    });
    _names.mount.add(name);
    if (field) {
      _updateDisabledField({
        field,
        disabled: isBoolean(options.disabled) ? options.disabled : props.disabled,
        name,
        value: options.value
      });
    } else {
      updateValidAndValue(name, true, options.value);
    }
    return {
      ...disabledIsDefined ? { disabled: options.disabled || props.disabled } : {},
      ..._options.progressive ? {
        required: !!options.required,
        min: getRuleValue(options.min),
        max: getRuleValue(options.max),
        minLength: getRuleValue(options.minLength),
        maxLength: getRuleValue(options.maxLength),
        pattern: getRuleValue(options.pattern)
      } : {},
      name,
      onChange,
      onBlur: onChange,
      ref: (ref) => {
        if (ref) {
          register(name, options);
          field = get(_fields, name);
          const fieldRef = isUndefined(ref.value) ? ref.querySelectorAll ? ref.querySelectorAll("input,select,textarea")[0] || ref : ref : ref;
          const radioOrCheckbox = isRadioOrCheckbox(fieldRef);
          const refs = field._f.refs || [];
          if (radioOrCheckbox ? refs.find((option) => option === fieldRef) : fieldRef === field._f.ref) {
            return;
          }
          set(_fields, name, {
            _f: {
              ...field._f,
              ...radioOrCheckbox ? {
                refs: [
                  ...refs.filter(live),
                  fieldRef,
                  ...Array.isArray(get(_defaultValues, name)) ? [{}] : []
                ],
                ref: { type: fieldRef.type, name }
              } : { ref: fieldRef }
            }
          });
          updateValidAndValue(name, false, void 0, fieldRef);
        } else {
          field = get(_fields, name, {});
          if (field._f) {
            field._f.mount = false;
          }
          (_options.shouldUnregister || options.shouldUnregister) && !(isNameInFieldArray(_names.array, name) && _state.action) && _names.unMount.add(name);
        }
      }
    };
  };
  const _focusError = () => _options.shouldFocusError && iterateFieldsByAction(_fields, _focusInput, _names.mount);
  const _disableForm = (disabled) => {
    if (isBoolean(disabled)) {
      _subjects.state.next({ disabled });
      iterateFieldsByAction(_fields, (ref, name) => {
        const currentField = get(_fields, name);
        if (currentField) {
          ref.disabled = currentField._f.disabled || disabled;
          if (Array.isArray(currentField._f.refs)) {
            currentField._f.refs.forEach((inputRef) => {
              inputRef.disabled = currentField._f.disabled || disabled;
            });
          }
        }
      }, 0, false);
    }
  };
  const handleSubmit = (onValid, onInvalid) => async (e) => {
    let onValidError = void 0;
    if (e) {
      e.preventDefault && e.preventDefault();
      e.persist && e.persist();
    }
    let fieldValues = cloneObject(_formValues);
    _subjects.state.next({
      isSubmitting: true
    });
    if (_options.resolver) {
      const { errors, values } = await _executeSchema();
      _formState.errors = errors;
      fieldValues = values;
    } else {
      await executeBuiltInValidation(_fields);
    }
    unset(_formState.errors, "root");
    if (isEmptyObject(_formState.errors)) {
      _subjects.state.next({
        errors: {}
      });
      try {
        await onValid(fieldValues, e);
      } catch (error) {
        onValidError = error;
      }
    } else {
      if (onInvalid) {
        await onInvalid({ ..._formState.errors }, e);
      }
      _focusError();
      setTimeout(_focusError);
    }
    _subjects.state.next({
      isSubmitted: true,
      isSubmitting: false,
      isSubmitSuccessful: isEmptyObject(_formState.errors) && !onValidError,
      submitCount: _formState.submitCount + 1,
      errors: _formState.errors
    });
    if (onValidError) {
      throw onValidError;
    }
  };
  const resetField = (name, options = {}) => {
    if (get(_fields, name)) {
      if (isUndefined(options.defaultValue)) {
        setValue(name, cloneObject(get(_defaultValues, name)));
      } else {
        setValue(name, options.defaultValue);
        set(_defaultValues, name, cloneObject(options.defaultValue));
      }
      if (!options.keepTouched) {
        unset(_formState.touchedFields, name);
      }
      if (!options.keepDirty) {
        unset(_formState.dirtyFields, name);
        _formState.isDirty = options.defaultValue ? _getDirty(name, cloneObject(get(_defaultValues, name))) : _getDirty();
      }
      if (!options.keepError) {
        unset(_formState.errors, name);
        _proxyFormState.isValid && _updateValid();
      }
      _subjects.state.next({ ..._formState });
    }
  };
  const _reset = (formValues, keepStateOptions = {}) => {
    const updatedValues = formValues ? cloneObject(formValues) : _defaultValues;
    const cloneUpdatedValues = cloneObject(updatedValues);
    const isEmptyResetValues = isEmptyObject(formValues);
    const values = isEmptyResetValues ? _defaultValues : cloneUpdatedValues;
    if (!keepStateOptions.keepDefaultValues) {
      _defaultValues = updatedValues;
    }
    if (!keepStateOptions.keepValues) {
      if (keepStateOptions.keepDirtyValues) {
        const fieldsToCheck = /* @__PURE__ */ new Set([
          ..._names.mount,
          ...Object.keys(getDirtyFields(_defaultValues, _formValues))
        ]);
        for (const fieldName of Array.from(fieldsToCheck)) {
          get(_formState.dirtyFields, fieldName) ? set(values, fieldName, get(_formValues, fieldName)) : setValue(fieldName, get(values, fieldName));
        }
      } else {
        if (isWeb && isUndefined(formValues)) {
          for (const name of _names.mount) {
            const field = get(_fields, name);
            if (field && field._f) {
              const fieldReference = Array.isArray(field._f.refs) ? field._f.refs[0] : field._f.ref;
              if (isHTMLElement(fieldReference)) {
                const form = fieldReference.closest("form");
                if (form) {
                  form.reset();
                  break;
                }
              }
            }
          }
        }
        _fields = {};
      }
      _formValues = props.shouldUnregister ? keepStateOptions.keepDefaultValues ? cloneObject(_defaultValues) : {} : cloneObject(values);
      _subjects.array.next({
        values: { ...values }
      });
      _subjects.values.next({
        values: { ...values }
      });
    }
    _names = {
      mount: keepStateOptions.keepDirtyValues ? _names.mount : /* @__PURE__ */ new Set(),
      unMount: /* @__PURE__ */ new Set(),
      array: /* @__PURE__ */ new Set(),
      watch: /* @__PURE__ */ new Set(),
      watchAll: false,
      focus: ""
    };
    _state.mount = !_proxyFormState.isValid || !!keepStateOptions.keepIsValid || !!keepStateOptions.keepDirtyValues;
    _state.watch = !!props.shouldUnregister;
    _subjects.state.next({
      submitCount: keepStateOptions.keepSubmitCount ? _formState.submitCount : 0,
      isDirty: isEmptyResetValues ? false : keepStateOptions.keepDirty ? _formState.isDirty : !!(keepStateOptions.keepDefaultValues && !deepEqual(formValues, _defaultValues)),
      isSubmitted: keepStateOptions.keepIsSubmitted ? _formState.isSubmitted : false,
      dirtyFields: isEmptyResetValues ? {} : keepStateOptions.keepDirtyValues ? keepStateOptions.keepDefaultValues && _formValues ? getDirtyFields(_defaultValues, _formValues) : _formState.dirtyFields : keepStateOptions.keepDefaultValues && formValues ? getDirtyFields(_defaultValues, formValues) : keepStateOptions.keepDirty ? _formState.dirtyFields : {},
      touchedFields: keepStateOptions.keepTouched ? _formState.touchedFields : {},
      errors: keepStateOptions.keepErrors ? _formState.errors : {},
      isSubmitSuccessful: keepStateOptions.keepIsSubmitSuccessful ? _formState.isSubmitSuccessful : false,
      isSubmitting: false
    });
  };
  const reset = (formValues, keepStateOptions) => _reset(isFunction(formValues) ? formValues(_formValues) : formValues, keepStateOptions);
  const setFocus = (name, options = {}) => {
    const field = get(_fields, name);
    const fieldReference = field && field._f;
    if (fieldReference) {
      const fieldRef = fieldReference.refs ? fieldReference.refs[0] : fieldReference.ref;
      if (fieldRef.focus) {
        fieldRef.focus();
        options.shouldSelect && fieldRef.select();
      }
    }
  };
  const _updateFormState = (updatedFormState) => {
    _formState = {
      ..._formState,
      ...updatedFormState
    };
  };
  const _resetDefaultValues = () => isFunction(_options.defaultValues) && _options.defaultValues().then((values) => {
    reset(values, _options.resetOptions);
    _subjects.state.next({
      isLoading: false
    });
  });
  return {
    control: {
      register,
      unregister,
      getFieldState,
      handleSubmit,
      setError,
      _executeSchema,
      _getWatch,
      _getDirty,
      _updateValid,
      _removeUnmounted,
      _updateFieldArray,
      _updateDisabledField,
      _getFieldArray,
      _reset,
      _resetDefaultValues,
      _updateFormState,
      _disableForm,
      _subjects,
      _proxyFormState,
      _setErrors,
      get _fields() {
        return _fields;
      },
      get _formValues() {
        return _formValues;
      },
      get _state() {
        return _state;
      },
      set _state(value) {
        _state = value;
      },
      get _defaultValues() {
        return _defaultValues;
      },
      get _names() {
        return _names;
      },
      set _names(value) {
        _names = value;
      },
      get _formState() {
        return _formState;
      },
      set _formState(value) {
        _formState = value;
      },
      get _options() {
        return _options;
      },
      set _options(value) {
        _options = {
          ..._options,
          ...value
        };
      }
    },
    trigger,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    resetField,
    clearErrors,
    unregister,
    setError,
    setFocus,
    getFieldState
  };
}
function useForm(props = {}) {
  const _formControl = React.useRef();
  const _values = React.useRef();
  const [formState, updateFormState] = React.useState({
    isDirty: false,
    isValidating: false,
    isLoading: isFunction(props.defaultValues),
    isSubmitted: false,
    isSubmitting: false,
    isSubmitSuccessful: false,
    isValid: false,
    submitCount: 0,
    dirtyFields: {},
    touchedFields: {},
    validatingFields: {},
    errors: props.errors || {},
    disabled: props.disabled || false,
    defaultValues: isFunction(props.defaultValues) ? void 0 : props.defaultValues
  });
  if (!_formControl.current) {
    _formControl.current = {
      ...createFormControl(props),
      formState
    };
  }
  const control = _formControl.current.control;
  control._options = props;
  useSubscribe({
    subject: control._subjects.state,
    next: (value) => {
      if (shouldRenderFormState(value, control._proxyFormState, control._updateFormState, true)) {
        updateFormState({ ...control._formState });
      }
    }
  });
  React.useEffect(() => control._disableForm(props.disabled), [control, props.disabled]);
  React.useEffect(() => {
    if (control._proxyFormState.isDirty) {
      const isDirty = control._getDirty();
      if (isDirty !== formState.isDirty) {
        control._subjects.state.next({
          isDirty
        });
      }
    }
  }, [control, formState.isDirty]);
  React.useEffect(() => {
    if (props.values && !deepEqual(props.values, _values.current)) {
      control._reset(props.values, control._options.resetOptions);
      _values.current = props.values;
      updateFormState((state) => ({ ...state }));
    } else {
      control._resetDefaultValues();
    }
  }, [props.values, control]);
  React.useEffect(() => {
    if (props.errors) {
      control._setErrors(props.errors);
    }
  }, [props.errors, control]);
  React.useEffect(() => {
    if (!control._state.mount) {
      control._updateValid();
      control._state.mount = true;
    }
    if (control._state.watch) {
      control._state.watch = false;
      control._subjects.state.next({ ...control._formState });
    }
    control._removeUnmounted();
  });
  React.useEffect(() => {
    props.shouldUnregister && control._subjects.values.next({
      values: control._getWatch()
    });
  }, [props.shouldUnregister, control]);
  React.useEffect(() => {
    if (_formControl.current) {
      _formControl.current.watch = _formControl.current.watch.bind({});
    }
  }, [formState]);
  _formControl.current.formState = getProxyFormState(formState, control);
  return _formControl.current;
}

// src/forms/userInfoForm.ts
import { zodResolver } from "@hookform/resolvers/zod";
var userInfoFormSchema = z.object({
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  phoneNumber: z.string().optional(),
  email: z.string().email(),
  gdprConsent: z.boolean().refine((value) => value === true, {
    message: "You need to accept the GDPR consent"
  }),
  betaTester: z.boolean().optional()
});
var useUserInfoForm = (user, onSuccess, onError) => {
  const form = useForm({
    resolver: zodResolver(userInfoFormSchema),
    defaultValues: {
      firstName: (user == null ? void 0 : user.firstName) ?? "",
      lastName: (user == null ? void 0 : user.lastName) ?? "",
      phoneNumber: (user == null ? void 0 : user.phoneNumber) ?? "",
      email: (user == null ? void 0 : user.email) ?? "",
      gdprConsent: (user == null ? void 0 : user.gdprConsent) ?? false,
      betaTester: (user == null ? void 0 : user.betaTester) ?? false
    }
  });
  const submit = async () => {
    var _a, _b;
    console.debug("Submitting form", form.getValues());
    const result = await user.updateSelf(form.getValues());
    if (result.kind !== "ok") {
      const fieldErrors = (_b = (_a = result.data) == null ? void 0 : _a.data) == null ? void 0 : _b.fieldErrors;
      if (fieldErrors) {
        Object.keys(fieldErrors).forEach((key) => {
          form.setError(key, { type: "manual", message: fieldErrors[key].join(", ") });
        });
      }
      onError && onError(result);
    } else {
      onSuccess && onSuccess(result.data);
    }
  };
  const onSubmit = form.handleSubmit(submit);
  return {
    form,
    onSubmit,
    schema: userInfoFormSchema
  };
};

// src/i18n/shared-tranlsations/en.json
var en_default = {
  models: {
    user: {
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      phoneNumber: "Phone",
      betaTester: "Beta tester",
      gdprConsent: "GDPR consent"
    },
    interruption: {
      interrupt: "Interrupt",
      reason: "Reason",
      reasons: {
        equipments: "Equipments",
        materials: "Materials",
        necessities: "Supplies",
        notDefined: "Not defined",
        other: "Other",
        plans: "Plans",
        prevTodo: "Previous todo",
        spaceNotAvailable: "Space not available",
        spaceNotClean: "Space not clean"
      },
      taskInterrupted: "Task interrupted"
    },
    todo: {
      note: "Note",
      blocked: "Blocked",
      description: "Description",
      late: "Late",
      scheduled: "Scheduled",
      upcoming: "Upcoming"
    },
    todoEvent: {
      completed: "Completed",
      continued: "Undone",
      resolved: "Resolved",
      started: "Started",
      cancelled: "Cancelled",
      skipped: "Skipped",
      unskipped: "Unskipped",
      interrupted: "Interrupted"
    },
    todos: {
      statuses: {
        completed: "Completed",
        disabled: "Disabled",
        enabled: "Enabled",
        interrupted: "Interrupted",
        skipped: "Skipped",
        started: "Started",
        waiting: "Waiting"
      }
    }
  }
};

// src/i18n/shared-tranlsations/fi.json
var fi_default = {
  models: {
    user: {
      firstName: "Etunimi",
      lastName: "Sukunimi",
      email: "S\xE4hk\xF6posti",
      phoneNumber: "Puhelinnumero",
      betaTester: "Beta-testaaja",
      gdprConsent: "GDPR-suostumus"
    },
    interruption: {
      interrupt: "Keskeyt\xE4",
      reason: "Syy",
      reasons: {
        equipments: "Kalusto ja ty\xF6koneet",
        materials: "Materiaalit",
        necessities: "Tarvikkeet",
        notDefined: "Ei m\xE4\xE4ritelty",
        other: "Muu syy",
        plans: "Suunnitelmat",
        prevTodo: "Edellinen ty\xF6vaihe",
        spaceNotAvailable: "Mesta ei ole vapaa",
        spaceNotClean: "Mesta ei ole siisti"
      },
      taskInterrupted: "Ty\xF6 keskeytetty"
    },
    todo: {
      note: "Huomio",
      blocked: "Keskeytetty",
      description: "Kuvaus",
      late: "My\xF6h\xE4ss\xE4",
      scheduled: "Suunniteltu",
      upcoming: "Tulossa"
    },
    todoEvent: {
      completed: "Valmis",
      continued: "Kumottu",
      resolved: "Ratkaistu",
      started: "Aloitettu",
      cancelled: "Peruttu",
      skipped: "Ohitettu",
      unskipped: "Ei ohitettu",
      interrupted: "Keskeytetty"
    },
    todos: {
      statuses: {
        completed: "Valmis",
        disabled: "Ei tekij\xE4\xE4",
        enabled: "Voi aloittaa",
        interrupted: "Keskeytetty",
        skipped: "Ei tehd\xE4",
        started: "Ty\xF6n alla",
        waiting: "Ei saa aloittaa"
      }
    }
  }
};

// src/i18n/index.ts
var sharedTranslations = {
  en: en_default,
  fi: fi_default
};
export {
  DataPlumberProvider,
  clear,
  generateCodeChallenge,
  generateCodeVerifier,
  getInitiationPKCEUrl,
  getLatestEventFromRRule,
  getRootStore,
  interruptionReasonTranslationKey,
  load,
  loadString,
  asyncStorage_exports as localStorage,
  models_exports as models,
  remove,
  save,
  saveString,
  setupRootStore,
  sharedTranslations,
  stores_exports as stores,
  todoEventTranslationKey,
  todoTranslationKey,
  useInitialRootStore,
  useStores,
  useUserInfoForm,
  userInfoFormSchema
};
