import { useMemo, useState, forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from './Input';
import { fallbackLocale } from '@/i18n';

interface LocaleNumInputProps {
  value?: number;
  onChange: (output: number | undefined) => void;
}

export const LocaleNumInput = forwardRef<HTMLInputElement, LocaleNumInputProps>(
  ({ value, onChange, ...props }, ref) => {
    const {
      i18n: { resolvedLanguage },
    } = useTranslation();

    // Get the correct decimal separator based on the locale
    const decimalSeparator = useMemo(() => {
      const numberFormat = new Intl.NumberFormat(resolvedLanguage || fallbackLocale);
      const parts = numberFormat.formatToParts(1.1);
      return parts.find((part) => part.type === 'decimal')?.value || '.';
    }, [resolvedLanguage]);

    const [displayValue, setDisplayValue] = useState(
      value?.toString().replace('.', decimalSeparator)
    );

    useEffect(() => {
      setDisplayValue(value?.toString().replace('.', decimalSeparator));
    }, [decimalSeparator, value]);

    // Handle input changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target.value;

      // Handle empty input case: reset to 0 when input is empty
      if (input === '') {
        setDisplayValue(input);
        onChange(undefined);
        return;
      }

      // Allow only digits and the decimal separator
      const sanitizedInput = input.replace(new RegExp(`[^0-9${decimalSeparator}]`, 'g'), '');

      // Ensure only one decimal separator is present
      const regex = new RegExp(`^\\d*${decimalSeparator}?\\d*$`);

      if (regex.test(sanitizedInput)) {
        // Update the display value with the sanitized input
        setDisplayValue(sanitizedInput);

        // Convert the value for internal use (replace decimalSeparator with dot)
        const valueForState = sanitizedInput.replace(decimalSeparator, '.');
        const parsedValue = parseFloat(valueForState);

        // If the parsed value is valid, update state
        if (!isNaN(parsedValue)) {
          onChange(parsedValue);
        }
      }
    };

    return (
      <Input
        ref={ref}
        type='numeric'
        value={displayValue} // Display value with the locale-specific separator
        onChange={handleChange} // Handle changes
        {...props}
      />
    );
  }
);
